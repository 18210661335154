/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CleanArtifactsRequest } from './CleanArtifactsRequest';
import { CleanArtifactsRequestFromJSON, CleanArtifactsRequestFromJSONTyped, CleanArtifactsRequestToJSON } from './CleanArtifactsRequest';
import type { RendringRequestParamsClonePhoto } from './RendringRequestParamsClonePhoto';
import {
  RendringRequestParamsClonePhotoFromJSON,
  RendringRequestParamsClonePhotoFromJSONTyped,
  RendringRequestParamsClonePhotoToJSON,
} from './RendringRequestParamsClonePhoto';
import type { RendringRequestParamsNewPhoto } from './RendringRequestParamsNewPhoto';
import {
  RendringRequestParamsNewPhotoFromJSON,
  RendringRequestParamsNewPhotoFromJSONTyped,
  RendringRequestParamsNewPhotoToJSON,
} from './RendringRequestParamsNewPhoto';

/**
 *
 * @export
 * @interface RendringRequestParams
 */
export interface RendringRequestParams {
  /**
   *
   * @type {string}
   * @memberof RendringRequestParams
   */
  type: RendringRequestParamsTypeEnum;
  /**
   *
   * @type {RendringRequestParamsClonePhoto}
   * @memberof RendringRequestParams
   */
  clone_photo?: RendringRequestParamsClonePhoto;
  /**
   *
   * @type {CleanArtifactsRequest}
   * @memberof RendringRequestParams
   */
  existing_photo?: CleanArtifactsRequest;
  /**
   *
   * @type {RendringRequestParamsNewPhoto}
   * @memberof RendringRequestParams
   */
  new_photo?: RendringRequestParamsNewPhoto;
}

/**
 * @export
 */
export const RendringRequestParamsTypeEnum = {
  New: 'new',
  Existing: 'existing',
  Clone: 'clone',
} as const;
export type RendringRequestParamsTypeEnum = (typeof RendringRequestParamsTypeEnum)[keyof typeof RendringRequestParamsTypeEnum];

/**
 * Check if a given object implements the RendringRequestParams interface.
 */
export function instanceOfRendringRequestParams(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'type' in value;

  return isInstance;
}

export function RendringRequestParamsFromJSON(json: any): RendringRequestParams {
  return RendringRequestParamsFromJSONTyped(json, false);
}

export function RendringRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RendringRequestParams {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    clone_photo: !exists(json, 'clone_photo') ? undefined : RendringRequestParamsClonePhotoFromJSON(json['clone_photo']),
    existing_photo: !exists(json, 'existing_photo') ? undefined : CleanArtifactsRequestFromJSON(json['existing_photo']),
    new_photo: !exists(json, 'new_photo') ? undefined : RendringRequestParamsNewPhotoFromJSON(json['new_photo']),
  };
}

export function RendringRequestParamsToJSON(value?: RendringRequestParams | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    clone_photo: RendringRequestParamsClonePhotoToJSON(value.clone_photo),
    existing_photo: CleanArtifactsRequestToJSON(value.existing_photo),
    new_photo: RendringRequestParamsNewPhotoToJSON(value.new_photo),
  };
}
