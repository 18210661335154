import PubSub from 'pubsub-js';
import { useEffect } from 'react';

const TOOL_SELECTED = 'tool.selected';
interface IToolSelected {
  name: string;
  options: Record<string, any>;
}

function publishSelectedTool(name: string, options = {}) {
  PubSub.publish(TOOL_SELECTED, Object.assign({}, options, { name }) as IToolSelected);
}

class PublishAndWait {
  topic: string = '';
  message: any;

  publish(topic: string, message: any) {
    this.topic = topic;
    this.message = message;
    return this;
  }

  async until(topic: string, timeout: number = 3000): Promise<any> {
    return new Promise((resolve, reject) => {
      let guard = setTimeout(() => reject('timeout exceeded in PublishAndWait.wait'), timeout);
      let subscriber = PubSub.subscribe(topic, (_topic: string, message: any) => {
        clearTimeout(guard);
        PubSub.unsubscribe(subscriber);
        resolve(message);
      });
      PubSub.publish(this.topic, this.message);
    });
  }
}

function publishAndWait(topic: string, options = {}) {
  return new PublishAndWait().publish(topic, options);
}

function publish(topic: string, options = {}) {
  PubSub.publish(topic, options);
}

const useSubscriber = (topic: string, handler: (topic: string, message: any) => void): void => {
  useEffect(() => {
    let listenerId = PubSub.subscribe(topic, handler);

    return () => {
      PubSub.unsubscribe(listenerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler]);
};

export { TOOL_SELECTED, publish, publishAndWait, publishSelectedTool, useSubscriber };
