/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Photo } from './Photo';
import { PhotoFromJSON, PhotoFromJSONTyped, PhotoToJSON } from './Photo';

/**
 *
 * @export
 * @interface PhotoListing
 */
export interface PhotoListing {
  /**
   *
   * @type {Array<Photo>}
   * @memberof PhotoListing
   */
  items: Array<Photo>;
}

/**
 * Check if a given object implements the PhotoListing interface.
 */
export function instanceOfPhotoListing(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'items' in value;

  return isInstance;
}

export function PhotoListingFromJSON(json: any): PhotoListing {
  return PhotoListingFromJSONTyped(json, false);
}

export function PhotoListingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoListing {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(PhotoFromJSON),
  };
}

export function PhotoListingToJSON(value?: PhotoListing | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(PhotoToJSON),
  };
}
