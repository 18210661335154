/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  Ack,
  CleanArtifactsRequest,
  CreatePhotoSessionPayload,
  Credits,
  GetPhotoSessionResponse,
  Photo,
  PhotoListing,
  PhotoSession,
  PhotoUpdateRequestPayload,
  RemoveProductRequest,
  RendringRequestParams,
  UpdatePhotoSessionPayload,
  UploadEditedPhotoResponse,
} from '../models';
import {
  AckFromJSON,
  AckToJSON,
  CleanArtifactsRequestFromJSON,
  CleanArtifactsRequestToJSON,
  CreatePhotoSessionPayloadFromJSON,
  CreatePhotoSessionPayloadToJSON,
  CreditsFromJSON,
  CreditsToJSON,
  GetPhotoSessionResponseFromJSON,
  GetPhotoSessionResponseToJSON,
  PhotoFromJSON,
  PhotoToJSON,
  PhotoListingFromJSON,
  PhotoListingToJSON,
  PhotoSessionFromJSON,
  PhotoSessionToJSON,
  PhotoUpdateRequestPayloadFromJSON,
  PhotoUpdateRequestPayloadToJSON,
  RemoveProductRequestFromJSON,
  RemoveProductRequestToJSON,
  RendringRequestParamsFromJSON,
  RendringRequestParamsToJSON,
  UpdatePhotoSessionPayloadFromJSON,
  UpdatePhotoSessionPayloadToJSON,
  UploadEditedPhotoResponseFromJSON,
  UploadEditedPhotoResponseToJSON,
} from '../models';

export interface PhotosApiCleanArtifactsOperationRequest {
  clean_artifacts_request?: CleanArtifactsRequest;
}

export interface PhotosApiCreatePhotoSessionRequest {
  create_photo_session_payload?: CreatePhotoSessionPayload;
}

export interface PhotosApiDeletePhotoRequest {
  id: string;
}

export interface PhotosApiGetPhotoRequest {
  id: string;
}

export interface PhotosApiGetPhotoSessionRequest {
  id: string;
}

export interface PhotosApiListFeaturedPhotosRequest {
  max_items?: string;
}

export interface PhotosApiListPhotosRequest {
  max_items?: string;
  cursor_serial?: string;
}

export interface PhotosApiPreRenderRequest {
  rendring_request_params?: RendringRequestParams;
}

export interface PhotosApiProgressiveRenderRequest {
  rendring_request_params?: RendringRequestParams;
}

export interface PhotosApiRasterizePhotoRequest {
  id: string;
}

export interface PhotosApiRefineRequest {
  clean_artifacts_request?: CleanArtifactsRequest;
}

export interface PhotosApiRemoveProductOperationRequest {
  remove_product_request?: RemoveProductRequest;
}

export interface PhotosApiRenderRequest {
  rendring_request_params?: RendringRequestParams;
}

export interface PhotosApiUpdatePhotoRequest {
  id: string;
  photo_update_request_payload?: PhotoUpdateRequestPayload;
}

export interface PhotosApiUpdatePhotoSessionRequest {
  id: string;
  update_photo_session_payload?: UpdatePhotoSessionPayload;
}

export interface PhotosApiUploadEditedPhotoRequest {
  id: string;
  type: string;
  image: Blob;
}

/**
 *
 */
export class PhotosApi extends runtime.BaseAPI {
  /**
   */
  async cleanArtifactsRaw(
    requestParameters: PhotosApiCleanArtifactsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Photo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/clean-artifacts`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CleanArtifactsRequestToJSON(requestParameters.clean_artifacts_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFromJSON(jsonValue));
  }

  /**
   */
  async cleanArtifacts(clean_artifacts_request?: CleanArtifactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Photo> {
    const response = await this.cleanArtifactsRaw({ clean_artifacts_request: clean_artifacts_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async createPhotoSessionRaw(
    requestParameters: PhotosApiCreatePhotoSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PhotoSession>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photo-sessions`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreatePhotoSessionPayloadToJSON(requestParameters.create_photo_session_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoSessionFromJSON(jsonValue));
  }

  /**
   */
  async createPhotoSession(
    create_photo_session_payload?: CreatePhotoSessionPayload,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PhotoSession> {
    const response = await this.createPhotoSessionRaw({ create_photo_session_payload: create_photo_session_payload }, initOverrides);
    return await response.value();
  }

  /**
   */
  async creditsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Credits>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/credits`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CreditsFromJSON(jsonValue));
  }

  /**
   */
  async credits(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Credits> {
    const response = await this.creditsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async deletePhotoRaw(
    requestParameters: PhotosApiDeletePhotoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Ack>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deletePhoto.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/destroy/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AckFromJSON(jsonValue));
  }

  /**
   */
  async deletePhoto(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Ack> {
    const response = await this.deletePhotoRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async getPhotoRaw(
    requestParameters: PhotosApiGetPhotoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Photo>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getPhoto.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/show/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFromJSON(jsonValue));
  }

  /**
   */
  async getPhoto(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Photo> {
    const response = await this.getPhotoRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async getPhotoSessionRaw(
    requestParameters: PhotosApiGetPhotoSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPhotoSessionResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getPhotoSession.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photo-sessions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetPhotoSessionResponseFromJSON(jsonValue));
  }

  /**
   */
  async getPhotoSession(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPhotoSessionResponse> {
    const response = await this.getPhotoSessionRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async listFeaturedPhotosRaw(
    requestParameters: PhotosApiListFeaturedPhotosRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PhotoListing>> {
    const queryParameters: any = {};

    if (requestParameters.max_items !== undefined) {
      queryParameters['max_items'] = requestParameters.max_items;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/featured`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoListingFromJSON(jsonValue));
  }

  /**
   */
  async listFeaturedPhotos(max_items?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhotoListing> {
    const response = await this.listFeaturedPhotosRaw({ max_items: max_items }, initOverrides);
    return await response.value();
  }

  /**
   */
  async listPhotosRaw(
    requestParameters: PhotosApiListPhotosRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PhotoListing>> {
    const queryParameters: any = {};

    if (requestParameters.max_items !== undefined) {
      queryParameters['max_items'] = requestParameters.max_items;
    }

    if (requestParameters.cursor_serial !== undefined) {
      queryParameters['cursor_serial'] = requestParameters.cursor_serial;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoListingFromJSON(jsonValue));
  }

  /**
   */
  async listPhotos(max_items?: string, cursor_serial?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhotoListing> {
    const response = await this.listPhotosRaw({ max_items: max_items, cursor_serial: cursor_serial }, initOverrides);
    return await response.value();
  }

  /**
   */
  async preRenderRaw(
    requestParameters: PhotosApiPreRenderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Photo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/rendering/pre-render`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RendringRequestParamsToJSON(requestParameters.rendring_request_params),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFromJSON(jsonValue));
  }

  /**
   */
  async preRender(rendring_request_params?: RendringRequestParams, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Photo> {
    const response = await this.preRenderRaw({ rendring_request_params: rendring_request_params }, initOverrides);
    return await response.value();
  }

  /**
   */
  async progressiveRenderRaw(
    requestParameters: PhotosApiProgressiveRenderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<string>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/rendering/progressive-render`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RendringRequestParamsToJSON(requestParameters.rendring_request_params),
      },
      initOverrides,
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async progressiveRender(rendring_request_params?: RendringRequestParams, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
    const response = await this.progressiveRenderRaw({ rendring_request_params: rendring_request_params }, initOverrides);
    return await response.value();
  }

  /**
   */
  async rasterizePhotoRaw(
    requestParameters: PhotosApiRasterizePhotoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Photo>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling rasterizePhoto.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/rasterize/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFromJSON(jsonValue));
  }

  /**
   */
  async rasterizePhoto(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Photo> {
    const response = await this.rasterizePhotoRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async refineRaw(requestParameters: PhotosApiRefineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Photo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/refine`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CleanArtifactsRequestToJSON(requestParameters.clean_artifacts_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFromJSON(jsonValue));
  }

  /**
   */
  async refine(clean_artifacts_request?: CleanArtifactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Photo> {
    const response = await this.refineRaw({ clean_artifacts_request: clean_artifacts_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async removeProductRaw(
    requestParameters: PhotosApiRemoveProductOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Photo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/remove-product`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RemoveProductRequestToJSON(requestParameters.remove_product_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFromJSON(jsonValue));
  }

  /**
   */
  async removeProduct(remove_product_request?: RemoveProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Photo> {
    const response = await this.removeProductRaw({ remove_product_request: remove_product_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async renderRaw(requestParameters: PhotosApiRenderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Photo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/rendering/render`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RendringRequestParamsToJSON(requestParameters.rendring_request_params),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFromJSON(jsonValue));
  }

  /**
   */
  async render(rendring_request_params?: RendringRequestParams, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Photo> {
    const response = await this.renderRaw({ rendring_request_params: rendring_request_params }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updatePhotoRaw(
    requestParameters: PhotosApiUpdatePhotoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Photo>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updatePhoto.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photos/update/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PhotoUpdateRequestPayloadToJSON(requestParameters.photo_update_request_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFromJSON(jsonValue));
  }

  /**
   */
  async updatePhoto(
    id: string,
    photo_update_request_payload?: PhotoUpdateRequestPayload,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Photo> {
    const response = await this.updatePhotoRaw({ id: id, photo_update_request_payload: photo_update_request_payload }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updatePhotoSessionRaw(
    requestParameters: PhotosApiUpdatePhotoSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PhotoSession>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updatePhotoSession.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/photo-sessions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: UpdatePhotoSessionPayloadToJSON(requestParameters.update_photo_session_payload),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoSessionFromJSON(jsonValue));
  }

  /**
   */
  async updatePhotoSession(
    id: string,
    update_photo_session_payload?: UpdatePhotoSessionPayload,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PhotoSession> {
    const response = await this.updatePhotoSessionRaw({ id: id, update_photo_session_payload: update_photo_session_payload }, initOverrides);
    return await response.value();
  }

  /**
   */
  async uploadEditedPhotoRaw(
    requestParameters: PhotosApiUploadEditedPhotoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UploadEditedPhotoResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling uploadEditedPhoto.');
    }

    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling uploadEditedPhoto.');
    }

    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling uploadEditedPhoto.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.type !== undefined) {
      formParams.append('type', requestParameters.type as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/photos/upload-edited-photo/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UploadEditedPhotoResponseFromJSON(jsonValue));
  }

  /**
   */
  async uploadEditedPhoto(
    id: string,
    type: string,
    image: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UploadEditedPhotoResponse> {
    const response = await this.uploadEditedPhotoRaw({ id: id, type: type, image: image }, initOverrides);
    return await response.value();
  }
}
