// @mui
// hooks
import { Divider, Stack } from '@mui/material';
// routes
// layouts
import LoginLayout from '../../layouts/login';
//

import AuthWithSocial from './AuthWithSocial';
import MagicLinkAuth from './MagicLinkAuth';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={3}>
        <AuthWithSocial />
        <Divider />

        <MagicLinkAuth />
      </Stack>
    </LoginLayout>
  );
}
