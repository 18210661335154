export type WindowWithAnalytics = Window & {
  heap: IHeap;
};

declare const window: WindowWithAnalytics;

export interface IHeap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

export class Heap {
  get enabled() {
    return process.env.REACT_APP_HEAP_ENVIRONMENT_ID !== undefined;
  }

  track(event: string, properties?: Object) {
    if (this.enabled && window.heap.track) {
      window.heap.track(event, properties);
    }
  }

  identify(identity: string) {
    if (this.enabled && window.heap.identify) {
      window.heap.identify(identity);
    }
  }

  resetIdentity() {
    if (this.enabled && window.heap.resetIdentity) {
      window.heap.resetIdentity();
    }
  }

  addUserProperties(properties: Object) {
    if (this.enabled && window.heap.addUserProperties) {
      window.heap.addUserProperties(properties);
    }
  }

  addEventProperties(properties: Object) {
    if (this.enabled && window.heap.addEventProperties) {
      window.heap.addEventProperties(properties);
    }
  }

  removeEventProperty(property: string) {
    if (this.enabled && window.heap.removeEventProperty) {
      window.heap.removeEventProperty(property);
    }
  }

  clearEventProperties() {
    if (this.enabled && window.heap.clearEventProperties) {
      window.heap.clearEventProperties();
    }
  }
}
