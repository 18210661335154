import { isEqual } from 'lodash';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useLogger } from 'src/utils/Logger';
import { Credits } from '../../generated/app_server_sdk';
import { usePolling } from '../../hooks/usePolling';
import appServer from '../../utils/appServer';

interface CreditsContextValue {
  credits: Credits | undefined;
  updateCredits: () => void;
}
const CreditsContext = createContext<CreditsContextValue | null>(null);

interface CreditsContextProviderProps {
  children?: ReactNode;
}

export function useCreditsContext() {
  const value = useContext(CreditsContext);

  if (!value) {
    throw new Error('CreditsContext value not found');
  }

  return value;
}

export function CreditsContextProvider({ children }: CreditsContextProviderProps) {
  let logger = useLogger();
  const [credits, setCredits] = useState<Credits | undefined>();
  const { forcePoll: updateCredits } = usePolling({
    enabled: true,
    interval: 300000,
    client: async () => {
      try {
        const creditsResponse = await appServer.photosApi.credits();
        if (!isEqual(credits, creditsResponse)) {
          setCredits(creditsResponse);
        }
      } catch (e) {
        logger.debug(e);
      }
    },
  });

  const value = useMemo(
    () => ({
      credits,
      updateCredits,
    }),
    [credits, updateCredits],
  );

  return <CreditsContext.Provider value={value}>{children}</CreditsContext.Provider>;
}
