/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RendringRequestParamsClonePhoto
 */
export interface RendringRequestParamsClonePhoto {
  /**
   *
   * @type {string}
   * @memberof RendringRequestParamsClonePhoto
   */
  source_id: string;
  /**
   *
   * @type {string}
   * @memberof RendringRequestParamsClonePhoto
   */
  target_id: string;
}

/**
 * Check if a given object implements the RendringRequestParamsClonePhoto interface.
 */
export function instanceOfRendringRequestParamsClonePhoto(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'source_id' in value;
  isInstance = isInstance && 'target_id' in value;

  return isInstance;
}

export function RendringRequestParamsClonePhotoFromJSON(json: any): RendringRequestParamsClonePhoto {
  return RendringRequestParamsClonePhotoFromJSONTyped(json, false);
}

export function RendringRequestParamsClonePhotoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RendringRequestParamsClonePhoto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    source_id: json['source_id'],
    target_id: json['target_id'],
  };
}

export function RendringRequestParamsClonePhotoToJSON(value?: RendringRequestParamsClonePhoto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    source_id: value.source_id,
    target_id: value.target_id,
  };
}
