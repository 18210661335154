/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LabsAlignProductsPlatformsRequest
 */
export interface LabsAlignProductsPlatformsRequest {
  /**
   *
   * @type {number}
   * @memberof LabsAlignProductsPlatformsRequest
   */
  platform_min_depth: number;
  /**
   *
   * @type {number}
   * @memberof LabsAlignProductsPlatformsRequest
   */
  image_width: number;
  /**
   *
   * @type {Array<string>}
   * @memberof LabsAlignProductsPlatformsRequest
   */
  foreground_images: Array<string>;
  /**
   *
   * @type {object}
   * @memberof LabsAlignProductsPlatformsRequest
   */
  background_image: object;
}

/**
 * Check if a given object implements the LabsAlignProductsPlatformsRequest interface.
 */
export function instanceOfLabsAlignProductsPlatformsRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'platform_min_depth' in value;
  isInstance = isInstance && 'image_width' in value;
  isInstance = isInstance && 'foreground_images' in value;
  isInstance = isInstance && 'background_image' in value;

  return isInstance;
}

export function LabsAlignProductsPlatformsRequestFromJSON(json: any): LabsAlignProductsPlatformsRequest {
  return LabsAlignProductsPlatformsRequestFromJSONTyped(json, false);
}

export function LabsAlignProductsPlatformsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabsAlignProductsPlatformsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    platform_min_depth: json['platform_min_depth'],
    image_width: json['image_width'],
    foreground_images: json['foreground_images'],
    background_image: json['background_image'],
  };
}

export function LabsAlignProductsPlatformsRequestToJSON(value?: LabsAlignProductsPlatformsRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    platform_min_depth: value.platform_min_depth,
    image_width: value.image_width,
    foreground_images: value.foreground_images,
    background_image: value.background_image,
  };
}
