/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LabsStudioShotRequest
 */
export interface LabsStudioShotRequest {
  /**
   *
   * @type {number}
   * @memberof LabsStudioShotRequest
   */
  max_height: number;
  /**
   *
   * @type {number}
   * @memberof LabsStudioShotRequest
   */
  max_width: number;
  /**
   *
   * @type {number}
   * @memberof LabsStudioShotRequest
   */
  center_y: number;
  /**
   *
   * @type {number}
   * @memberof LabsStudioShotRequest
   */
  center_x: number;
  /**
   *
   * @type {string}
   * @memberof LabsStudioShotRequest
   */
  background_url: string;
  /**
   *
   * @type {string}
   * @memberof LabsStudioShotRequest
   */
  foreground_url: string;
  /**
   *
   * @type {number}
   * @memberof LabsStudioShotRequest
   */
  height: number;
  /**
   *
   * @type {number}
   * @memberof LabsStudioShotRequest
   */
  width: number;
  /**
   *
   * @type {string}
   * @memberof LabsStudioShotRequest
   */
  prompt: string;
}

/**
 * Check if a given object implements the LabsStudioShotRequest interface.
 */
export function instanceOfLabsStudioShotRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'max_height' in value;
  isInstance = isInstance && 'max_width' in value;
  isInstance = isInstance && 'center_y' in value;
  isInstance = isInstance && 'center_x' in value;
  isInstance = isInstance && 'background_url' in value;
  isInstance = isInstance && 'foreground_url' in value;
  isInstance = isInstance && 'height' in value;
  isInstance = isInstance && 'width' in value;
  isInstance = isInstance && 'prompt' in value;

  return isInstance;
}

export function LabsStudioShotRequestFromJSON(json: any): LabsStudioShotRequest {
  return LabsStudioShotRequestFromJSONTyped(json, false);
}

export function LabsStudioShotRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabsStudioShotRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    max_height: json['max_height'],
    max_width: json['max_width'],
    center_y: json['center_y'],
    center_x: json['center_x'],
    background_url: json['background_url'],
    foreground_url: json['foreground_url'],
    height: json['height'],
    width: json['width'],
    prompt: json['prompt'],
  };
}

export function LabsStudioShotRequestToJSON(value?: LabsStudioShotRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    max_height: value.max_height,
    max_width: value.max_width,
    center_y: value.center_y,
    center_x: value.center_x,
    background_url: value.background_url,
    foreground_url: value.foreground_url,
    height: value.height,
    width: value.width,
    prompt: value.prompt,
  };
}
