/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductPlacement } from './ProductPlacement';
import { ProductPlacementFromJSON, ProductPlacementFromJSONTyped, ProductPlacementToJSON } from './ProductPlacement';

/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {number}
   * @memberof Product
   */
  length?: number;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  id: string;
  /**
   *
   * @type {ProductPlacement}
   * @memberof Product
   */
  placement: ProductPlacement;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'placement' in value;

  return isInstance;
}

export function ProductFromJSON(json: any): Product {
  return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    length: !exists(json, 'length') ? undefined : json['length'],
    name: json['name'],
    id: json['id'],
    placement: ProductPlacementFromJSON(json['placement']),
  };
}

export function ProductToJSON(value?: Product | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    length: value.length,
    name: value.name,
    id: value.id,
    placement: ProductPlacementToJSON(value.placement),
  };
}
