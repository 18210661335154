import { LazyMotion, m } from 'framer-motion';
import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions
const loadFeatures = () => import('./features.js').then((res) => res.default);

type Props = {
  children: React.ReactNode;
  animate?: boolean | undefined;
  style?: CSSProperties | undefined;
};

export default function MotionLazyContainer({ children }: Props) {
  return (
    <LazyMotion strict features={loadFeatures}>
      <m.div style={{ height: '100%' }}> {children} </m.div>
    </LazyMotion>
  );
}

export function MotionLazyPulsingContainer({ children, animate, style }: Props) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {animate ? (
        <m.div
          className='container'
          animate={{ opacity: [1, 0.4, 1] }}
          transition={{
            ease: 'linear',
            repeat: Infinity,
            duration: 3,
          }}
          style={style}
        >
          {children}
        </m.div>
      ) : (
        children
      )}
    </LazyMotion>
  );
}

export function MotionSlowPulseContainer({ children, animate, style }: Props) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {animate ? (
        <m.div
          className='container'
          animate={{ opacity: [1, 0.4, 1] }}
          transition={{
            ease: 'easeInOut',
            repeat: Infinity,
            duration: 4,
          }}
          style={style}
        >
          {children}
        </m.div>
      ) : (
        children
      )}
    </LazyMotion>
  );
}
