/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PhotoEditHistory } from './PhotoEditHistory';
import { PhotoEditHistoryFromJSON, PhotoEditHistoryFromJSONTyped, PhotoEditHistoryToJSON } from './PhotoEditHistory';

/**
 *
 * @export
 * @interface PhotoUpdateRequestPayload
 */
export interface PhotoUpdateRequestPayload {
  /**
   *
   * @type {PhotoEditHistory}
   * @memberof PhotoUpdateRequestPayload
   */
  edit_history?: PhotoEditHistory;
}

/**
 * Check if a given object implements the PhotoUpdateRequestPayload interface.
 */
export function instanceOfPhotoUpdateRequestPayload(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PhotoUpdateRequestPayloadFromJSON(json: any): PhotoUpdateRequestPayload {
  return PhotoUpdateRequestPayloadFromJSONTyped(json, false);
}

export function PhotoUpdateRequestPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoUpdateRequestPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    edit_history: !exists(json, 'edit_history') ? undefined : PhotoEditHistoryFromJSON(json['edit_history']),
  };
}

export function PhotoUpdateRequestPayloadToJSON(value?: PhotoUpdateRequestPayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    edit_history: PhotoEditHistoryToJSON(value.edit_history),
  };
}
