/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VersionResponse
 */
export interface VersionResponse {
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  app: string;
  /**
   *
   * @type {string}
   * @memberof VersionResponse
   */
  model: string;
}

/**
 * Check if a given object implements the VersionResponse interface.
 */
export function instanceOfVersionResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'app' in value;
  isInstance = isInstance && 'model' in value;

  return isInstance;
}

export function VersionResponseFromJSON(json: any): VersionResponse {
  return VersionResponseFromJSONTyped(json, false);
}

export function VersionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    app: json['app'],
    model: json['model'],
  };
}

export function VersionResponseToJSON(value?: VersionResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    app: value.app,
    model: value.model,
  };
}
