import { ReactNode } from 'react';
// @mui
import { Stack, Typography } from '@mui/material';
//
import { StyledContent, StyledRoot, StyledSection, StyledSectionBg, Video } from './styles';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const LOGO_ICON_URL = 'https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png';

function AppTitle(props: { titleText?: string; subitleText?: string }) {
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <img src={LOGO_ICON_URL} width='40' height='40' alt='final touch' />
      <Typography variant='h3' sx={{ maxWidth: 480, mb: 1, textAlign: 'center' }}>
        {props.titleText || 'Hi, Welcome to Final Touch'}
      </Typography>
      <Typography variant='subtitle1' sx={{ maxWidth: 410, textAlign: 'center' }}>
        {props.subitleText || 'Let our AI create scroll-stopping product images for you. Try for free!'}
      </Typography>
    </Stack>
  );
}

const DESKTOP_VIDEO_URL = 'https://res.cloudinary.com/ft-bounty/video/upload/f_auto:video,q_auto/v1687779482/website-materials/FinalTouch_HP_Desktop';
const DESKTOP_VIDEO_POSTER_URL =
  'https://res.cloudinary.com/ft-bounty/video/upload/f_auto,q_auto,so_2.5/v1687779482/website-materials/FinalTouch_HP_Desktop.jpeg';
const MOBILE_VIDEO_URL = 'https://res.cloudinary.com/ft-bounty/video/upload/f_auto:video,q_auto/v1687779482/website-materials/FinalTouch_HP_VisualsOnly_V1';
const MOBILE_VIDEO_POSTER_URL =
  'https://res.cloudinary.com/ft-bounty/video/upload/f_auto,q_auto,so_2.5/v1687779482/website-materials/FinalTouch_HP_VisualsOnly_V1';

type Props = {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
};

function AutoplayedVideo(props: { src: string; poster: string }) {
  return <Video {...props} playsInline loop autoPlay muted />;
}

export default function LoginLayout({ children, title, subtitle }: Props) {
  const isDesktop = useResponsive('up', 'md');

  return (
    <StyledRoot>
      <StyledSection>
        <Stack spacing={10} sx={{ alignItems: 'center' }}>
          <AppTitle titleText={title} subitleText={subtitle} />
          {isDesktop && <AutoplayedVideo src={DESKTOP_VIDEO_URL} poster={DESKTOP_VIDEO_POSTER_URL} />}
        </Stack>
        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }} spacing={5}>
          {!isDesktop && <AppTitle titleText={title} subitleText={subtitle} />}
          {children}
          {!isDesktop && <AutoplayedVideo src={MOBILE_VIDEO_URL} poster={MOBILE_VIDEO_POSTER_URL} />}
        </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
