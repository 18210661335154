import {
  AppBar,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import LABS_LIST from 'src/assets/data/labs';
import Iconify from '../iconify/Iconify';

export default function MobileHeader() {
  let [drawerOpen, setDrawerOpen] = useState(false);

  let [isVirtualPhotoshootSubmenuOpen, setIsVirtualPhotoshootSubmenuOpen] = useState(false);
  let [isLabsSubmenuOpen, setIsLabsSubmenuOpen] = useState(false);

  const [fullscreen, setFullscreen] = useState(false);

  const onToggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  let navigate = useNavigate();
  return (
    <Box>
      <AppBar position='fixed'>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => {
              setDrawerOpen(true);
            }}
          >
            <Iconify icon='material-symbols:menu' />
          </IconButton>
          <Typography variant='h6' noWrap component='div'>
            Final Touch
          </Typography>
          <Box flexGrow={1} />
          <Divider />
          <Stack spacing={2} direction='row'>
            <img src='https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png' width='30' height='30' alt='final touch' />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <Box sx={{ height: '55px', overflow: 'hidden', width: 300 }}>
          <IconButton
            sx={{ marginTop: '5px' }}
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            <Iconify icon='material-symbols:chevron-left' width={33} height={22} />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/');
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Iconify icon='material-symbols:home' />
              </ListItemIcon>
              <ListItemText primary='Home' />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/virtual-photoshoot');
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Iconify icon='dashicons:images-alt' />
              </ListItemIcon>
              <ListItemText primary='Virtual Photoshoot' />
              <IconButton
                onClick={(event) => {
                  setIsVirtualPhotoshootSubmenuOpen((curr) => !curr);
                  event.stopPropagation();
                }}
              >
                {isVirtualPhotoshootSubmenuOpen ? <Iconify icon='ic:baseline-expand-less' /> : <Iconify icon='ic:baseline-expand-more' />}
              </IconButton>
            </ListItemButton>
          </ListItem>
          <Collapse in={isVirtualPhotoshootSubmenuOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('/assets/list');
                  setDrawerOpen(false);
                }}
              >
                <ListItemIcon>
                  <Iconify icon='material-symbols:camera' />
                </ListItemIcon>
                <ListItemText primary='Products' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate('/creations/list');
                  setDrawerOpen(false);
                }}
              >
                <ListItemIcon>
                  <Iconify icon='material-symbols:photo-album' />
                </ListItemIcon>
                <ListItemText primary='Photoshoots' />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/#labs');
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Iconify icon='material-symbols:camera' />
              </ListItemIcon>
              <ListItemText primary='Labs' />
              <IconButton
                onClick={(event) => {
                  setIsLabsSubmenuOpen((curr) => !curr);
                  event.stopPropagation();
                }}
              >
                {isLabsSubmenuOpen ? <Iconify icon='ic:baseline-expand-less' /> : <Iconify icon='ic:baseline-expand-more' />}
              </IconButton>
            </ListItemButton>
          </ListItem>
          <Collapse in={isLabsSubmenuOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {LABS_LIST.map((lab) => (
                <ListItemButton
                  key={lab.navigateUrl}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate(lab.navigateUrl);
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <Iconify icon={lab.icon} />
                  </ListItemIcon>
                  <ListItemText primary={lab.name} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('account/settings');
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Iconify icon='material-symbols:settings' />
              </ListItemIcon>
              <ListItemText primary={'Settings'} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={onToggleFullScreen}>
              <ListItemIcon>
                <Iconify icon={fullscreen ? 'material-symbols:fullscreen-exit' : 'material-symbols:fullscreen'} />
              </ListItemIcon>
              <ListItemText primary={fullscreen ? 'Exit Fullscreen' : 'Fullscreen'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
