/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UsersIdentityUpdatePostRequest
 */
export interface UsersIdentityUpdatePostRequest {
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  promo_code: string;
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  zip_code: string;
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  phone_number: string;
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UsersIdentityUpdatePostRequest
   */
  email: string;
}

/**
 * Check if a given object implements the UsersIdentityUpdatePostRequest interface.
 */
export function instanceOfUsersIdentityUpdatePostRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'promo_code' in value;
  isInstance = isInstance && 'address' in value;
  isInstance = isInstance && 'state' in value;
  isInstance = isInstance && 'zip_code' in value;
  isInstance = isInstance && 'city' in value;
  isInstance = isInstance && 'country' in value;
  isInstance = isInstance && 'phone_number' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'email' in value;

  return isInstance;
}

export function UsersIdentityUpdatePostRequestFromJSON(json: any): UsersIdentityUpdatePostRequest {
  return UsersIdentityUpdatePostRequestFromJSONTyped(json, false);
}

export function UsersIdentityUpdatePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersIdentityUpdatePostRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    promo_code: json['promo_code'],
    address: json['address'],
    state: json['state'],
    zip_code: json['zip_code'],
    city: json['city'],
    country: json['country'],
    phone_number: json['phone_number'],
    name: json['name'],
    email: json['email'],
  };
}

export function UsersIdentityUpdatePostRequestToJSON(value?: UsersIdentityUpdatePostRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    promo_code: value.promo_code,
    address: value.address,
    state: value.state,
    zip_code: value.zip_code,
    city: value.city,
    country: value.country,
    phone_number: value.phone_number,
    name: value.name,
    email: value.email,
  };
}
