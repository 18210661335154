/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OnboardingQuestionAnswer } from './OnboardingQuestionAnswer';
import { OnboardingQuestionAnswerFromJSON, OnboardingQuestionAnswerFromJSONTyped, OnboardingQuestionAnswerToJSON } from './OnboardingQuestionAnswer';

/**
 *
 * @export
 * @interface OnboardingQuestion
 */
export interface OnboardingQuestion {
  /**
   *
   * @type {string}
   * @memberof OnboardingQuestion
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingQuestion
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingQuestion
   */
  type: OnboardingQuestionTypeEnum;
  /**
   *
   * @type {Array<OnboardingQuestionAnswer>}
   * @memberof OnboardingQuestion
   */
  answers: Array<OnboardingQuestionAnswer>;
}

/**
 * @export
 */
export const OnboardingQuestionTypeEnum = {
  Open: 'open',
  SingleChoice: 'single_choice',
  MultipleChoice: 'multiple_choice',
} as const;
export type OnboardingQuestionTypeEnum = (typeof OnboardingQuestionTypeEnum)[keyof typeof OnboardingQuestionTypeEnum];

/**
 * Check if a given object implements the OnboardingQuestion interface.
 */
export function instanceOfOnboardingQuestion(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'text' in value;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'answers' in value;

  return isInstance;
}

export function OnboardingQuestionFromJSON(json: any): OnboardingQuestion {
  return OnboardingQuestionFromJSONTyped(json, false);
}

export function OnboardingQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    text: json['text'],
    type: json['type'],
    answers: (json['answers'] as Array<any>).map(OnboardingQuestionAnswerFromJSON),
  };
}

export function OnboardingQuestionToJSON(value?: OnboardingQuestion | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    text: value.text,
    type: value.type,
    answers: (value.answers as Array<any>).map(OnboardingQuestionAnswerToJSON),
  };
}
