/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Ack
 */
export interface Ack {
  /**
   *
   * @type {string}
   * @memberof Ack
   */
  id: string;
}

/**
 * Check if a given object implements the Ack interface.
 */
export function instanceOfAck(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;

  return isInstance;
}

export function AckFromJSON(json: any): Ack {
  return AckFromJSONTyped(json, false);
}

export function AckFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ack {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
  };
}

export function AckToJSON(value?: Ack | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
  };
}
