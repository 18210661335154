import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps, Link } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  isMobile?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, isMobile = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component='div'
      sx={{
        width: other.width || 40,
        height: other.height || 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img src='https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png' width='40' height='40' alt='final touch' />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  if (isMobile) {
    return (
      <Link to='/' component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }

  return (
    <Link to='/' component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

export default Logo;
