/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Credits
 */
export interface Credits {
  /**
   *
   * @type {number}
   * @memberof Credits
   */
  remaining: number;
  /**
   *
   * @type {number}
   * @memberof Credits
   */
  cap: number;
  /**
   *
   * @type {string}
   * @memberof Credits
   */
  until: string;
}

/**
 * Check if a given object implements the Credits interface.
 */
export function instanceOfCredits(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'remaining' in value;
  isInstance = isInstance && 'cap' in value;
  isInstance = isInstance && 'until' in value;

  return isInstance;
}

export function CreditsFromJSON(json: any): Credits {
  return CreditsFromJSONTyped(json, false);
}

export function CreditsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Credits {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    remaining: json['remaining'],
    cap: json['cap'],
    until: json['until'],
  };
}

export function CreditsToJSON(value?: Credits | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    remaining: value.remaining,
    cap: value.cap,
    until: value.until,
  };
}
