/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  Ack,
  OnboardingFormSubmissionBody,
  OnboardingQuestion,
  PostAnalyticsEventsRequest,
  UpdateUserSettingsRequest,
  User,
  UsersIdentityUpdatePostRequest,
  VerifyCloudinaryCredentailsRequest,
  VersionResponse,
  WalledGardenResponse,
} from '../models';
import {
  AckFromJSON,
  AckToJSON,
  OnboardingFormSubmissionBodyFromJSON,
  OnboardingFormSubmissionBodyToJSON,
  OnboardingQuestionFromJSON,
  OnboardingQuestionToJSON,
  PostAnalyticsEventsRequestFromJSON,
  PostAnalyticsEventsRequestToJSON,
  UpdateUserSettingsRequestFromJSON,
  UpdateUserSettingsRequestToJSON,
  UserFromJSON,
  UserToJSON,
  UsersIdentityUpdatePostRequestFromJSON,
  UsersIdentityUpdatePostRequestToJSON,
  VerifyCloudinaryCredentailsRequestFromJSON,
  VerifyCloudinaryCredentailsRequestToJSON,
  VersionResponseFromJSON,
  VersionResponseToJSON,
  WalledGardenResponseFromJSON,
  WalledGardenResponseToJSON,
} from '../models';

export interface UtilsApiPostAnalyticsEventsOperationRequest {
  post_analytics_events_request?: PostAnalyticsEventsRequest;
}

export interface UtilsApiPostOnboardingFormRequest {
  onboarding_form_submission_body?: OnboardingFormSubmissionBody;
}

export interface UtilsApiUpdateUserSettingsOperationRequest {
  update_user_settings_request?: UpdateUserSettingsRequest;
}

export interface UtilsApiUsersIdentityUpdatePostOperationRequest {
  users_identity_update_post_request?: UsersIdentityUpdatePostRequest;
}

export interface UtilsApiVerifyCloudinaryCredentailsOperationRequest {
  verify_cloudinary_credentails_request?: VerifyCloudinaryCredentailsRequest;
}

/**
 *
 */
export class UtilsApi extends runtime.BaseAPI {
  /**
   */
  async getOnboardingQuestionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OnboardingQuestion>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/onboarding/questions`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OnboardingQuestionFromJSON));
  }

  /**
   */
  async getOnboardingQuestions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OnboardingQuestion>> {
    const response = await this.getOnboardingQuestionsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async identityRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/identity`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
  }

  /**
   */
  async identity(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
    const response = await this.identityRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async postAnalyticsEventsRaw(
    requestParameters: UtilsApiPostAnalyticsEventsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Ack>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/analytics`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PostAnalyticsEventsRequestToJSON(requestParameters.post_analytics_events_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AckFromJSON(jsonValue));
  }

  /**
   */
  async postAnalyticsEvents(
    post_analytics_events_request?: PostAnalyticsEventsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Ack> {
    const response = await this.postAnalyticsEventsRaw({ post_analytics_events_request: post_analytics_events_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async postOnboardingFormRaw(
    requestParameters: UtilsApiPostOnboardingFormRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/onboarding-form`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OnboardingFormSubmissionBodyToJSON(requestParameters.onboarding_form_submission_body),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async postOnboardingForm(
    onboarding_form_submission_body?: OnboardingFormSubmissionBody,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.postOnboardingFormRaw({ onboarding_form_submission_body: onboarding_form_submission_body }, initOverrides);
  }

  /**
   */
  async updateUserSettingsRaw(
    requestParameters: UtilsApiUpdateUserSettingsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<User>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/settings/update`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateUserSettingsRequestToJSON(requestParameters.update_user_settings_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
  }

  /**
   */
  async updateUserSettings(
    update_user_settings_request?: UpdateUserSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<User> {
    const response = await this.updateUserSettingsRaw({ update_user_settings_request: update_user_settings_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async usersIdentityUpdatePostRaw(
    requestParameters: UtilsApiUsersIdentityUpdatePostOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<User>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/identity/update`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UsersIdentityUpdatePostRequestToJSON(requestParameters.users_identity_update_post_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
  }

  /**
   */
  async usersIdentityUpdatePost(
    users_identity_update_post_request?: UsersIdentityUpdatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<User> {
    const response = await this.usersIdentityUpdatePostRaw({ users_identity_update_post_request: users_identity_update_post_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async verifyCloudinaryCredentailsRaw(
    requestParameters: UtilsApiVerifyCloudinaryCredentailsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/settings/verifyCloudinaryCredentails`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: VerifyCloudinaryCredentailsRequestToJSON(requestParameters.verify_cloudinary_credentails_request),
      },
      initOverrides,
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async verifyCloudinaryCredentails(
    verify_cloudinary_credentails_request?: VerifyCloudinaryCredentailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.verifyCloudinaryCredentailsRaw({ verify_cloudinary_credentails_request: verify_cloudinary_credentails_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async versionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VersionResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/utils/version`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => VersionResponseFromJSON(jsonValue));
  }

  /**
   */
  async version(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VersionResponse> {
    const response = await this.versionRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async walledgardenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WalledGardenResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/public/walledgarden`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WalledGardenResponseFromJSON(jsonValue));
  }

  /**
   */
  async walledgarden(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WalledGardenResponse> {
    const response = await this.walledgardenRaw(initOverrides);
    return await response.value();
  }
}
