import { finder } from '@medv/finder';
import md5 from 'md5';
import { subscribe } from 'pubsub-js';
import appServer from './appServer';
import { Heap } from './heap';

export type WindowWithAnalytics = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithAnalytics;
// all payloads should be configured in tag manager https://tagmanager.google.com/#/container/accounts/48307554/containers/97652199/workspaces/4/tags
interface ITrackedEventPayload {
  internal?: boolean;
  user_id?: string;
  occurrence?: string;
  source?: string;
  event_value?: string;
  selectors?: {
    context?: string;
    id?: string;
    tag?: string;
    class_name?: string;
    query_selector?: string;
    text?: string;
    url?: string;
  };
}

interface ITrackedEvent extends ITrackedEventPayload {
  event_name: string;
  event?: string;
  path?: string;
}

interface IIntercomEvent extends ITrackedEventPayload {
  event_name?: string | undefined;
}
class Analytics {
  context: ITrackedEventPayload;
  eventsBuffer: ITrackedEvent[];
  intecomSink: undefined | any;
  heap: Heap;
  constructor() {
    this.context = {};
    this.eventsBuffer = [];
    this.heap = new Heap();
    setInterval(
      (analytics) => {
        analytics.flushBufferIfNeeded(true);
      },
      10000,
      this,
    );
  }

  appendIntercomSink(trackingMethod: (event: string, metaData?: object) => void) {
    this.intecomSink = trackingMethod;
  }

  set(update: ITrackedEventPayload) {
    Object.assign(this.context, update);
  }

  track(event: ITrackedEvent) {
    let globals = {
      event: 'event to GA',
      path: window.location.pathname,
    };
    let eventWithContetx = Object.assign({}, this.context, event, globals);

    this.eventsBuffer.push(Object.assign({}, eventWithContetx, { time: new Date() }));
    if (!event.internal) {
      window.dataLayer.push(eventWithContetx);
    }

    this.flushBufferIfNeeded();
  }

  async flushBufferIfNeeded(forceFlush: boolean = false) {
    if (this.eventsBuffer.length > 10 || forceFlush) {
      let toFlush = this.eventsBuffer.splice(0, this.eventsBuffer.length);
      if (toFlush.length > 0) {
        appServer.utilsApi.postAnalyticsEvents({ events: toFlush }).catch(() => {
          /* ignore analytics send failures */
        });
        if (this.intecomSink) {
          toFlush.forEach((event) => {
            if (!event.internal || (event.event_name === 'click' && event.selectors?.context)) {
              let intercomEvent: IIntercomEvent = Object.assign({}, event) as IIntercomEvent;
              delete intercomEvent.event_name;
              let { event_name } = event;

              if (event_name === 'page_load') {
                event_name = `${event_name}:${event.path}`;
              } else if (event_name === 'click' && event.selectors?.context) {
                event_name = `${event_name}:${event.selectors.context}`;
              }
              this.intecomSink(event_name, intercomEvent);
            }
          });
        }
      }
    }
  }
}
const analytics = new Analytics();

subscribe('log', (mesage, data) => {
  analytics.track({ event_name: 'log', event_value: JSON.stringify(data), internal: true });
});

function ancestors(target: HTMLElement | null, level = MAX_ANCESTOR_LEVEL): HTMLElement[] {
  if (level === 0 || !target) {
    return [];
  }
  return [target, ...ancestors(target.parentElement, level - 1)];
}
const MAX_ANCESTOR_LEVEL = 15;

export function analyticsContext(target: HTMLElement | null, level = MAX_ANCESTOR_LEVEL): string | undefined {
  const elements = ancestors(target, level);
  const ids = elements
    .filter((element) => element.dataset.analyticsId)
    .map((element) => element.dataset.analyticsId)
    .reverse();
  return ids.join('.');
}

export class GlobalEventTracker {
  initialized: boolean;
  constructor() {
    this.initialized = false;
  }

  init() {
    if (!this.initialized) {
      this.initialized = true;
      this.registerGlobalClickTracking();
    }
  }

  registerGlobalClickTracking() {
    // capture true - to catch events before they are processed by other event listeners
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#capture
    document.addEventListener('click', this.clickEventsTracker, { capture: true });
  }

  clickEventsTracker(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target) {
      const selector = finder(target);
      let text = target.innerText;
      if (text && text.length > 20) {
        text = `${text.substring(0, 20)} [${md5(text)}]`;
      }
      const event = {
        internal: true,
        event_name: 'click',
        event_value: target.dataset.analyticsEventValue,
        selectors: {
          tag: (target.tagName || '').toLowerCase(),
          class_name: target.className,
          id: target.id,
          query_selector: selector,
          context: analyticsContext(target),
          text,
          page: `${window.location.pathname}${window.location.search}`,
        },
      };
      analytics.track(event);
    }
  }
}

export default analytics;
