/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LabsTextToImageRequest
 */
export interface LabsTextToImageRequest {
  /**
   *
   * @type {number}
   * @memberof LabsTextToImageRequest
   */
  height: number;
  /**
   *
   * @type {number}
   * @memberof LabsTextToImageRequest
   */
  width: number;
  /**
   *
   * @type {string}
   * @memberof LabsTextToImageRequest
   */
  prompt: string;
}

/**
 * Check if a given object implements the LabsTextToImageRequest interface.
 */
export function instanceOfLabsTextToImageRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'height' in value;
  isInstance = isInstance && 'width' in value;
  isInstance = isInstance && 'prompt' in value;

  return isInstance;
}

export function LabsTextToImageRequestFromJSON(json: any): LabsTextToImageRequest {
  return LabsTextToImageRequestFromJSONTyped(json, false);
}

export function LabsTextToImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabsTextToImageRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    height: json['height'],
    width: json['width'],
    prompt: json['prompt'],
  };
}

export function LabsTextToImageRequestToJSON(value?: LabsTextToImageRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    height: value.height,
    width: value.width,
    prompt: value.prompt,
  };
}
