let error404Urls = [
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694113786/app-materials/lottie/404_cxqscc.json',
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694113790/app-materials/lottie/404_alt_qyv4ka.json',
];
export async function getAnimation(url: string) {
  return fetch(url).then((resp) => resp.json());
}
function sample(arr: string[]) {
  let idx = Math.round(Math.random() * (arr.length - 1));
  return arr[idx];
}
export async function error404Animation() {
  return getAnimation(sample(error404Urls));
}

const loadingUrls = [
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694112137/app-materials/lottie/catonbike2_hfnrvq.json',
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694112137/app-materials/lottie/walk_k10qwo.json',
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694112131/app-materials/lottie/robothands_z4wzf0.json',
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694112131/app-materials/lottie/rainbowcat_pze6tf.json',
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694112130/app-materials/lottie/catonbike_qahwbi.json',
  // commented because it doesn't really fit the app's feel
  // 'https://res.cloudinary.com/ft-bounty/raw/upload/v1694112130/app-materials/lottie/dance_ln1puj.json',
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694112129/app-materials/lottie/scale_ecqi0f.json',
  'https://res.cloudinary.com/ft-bounty/raw/upload/v1694112125/app-materials/lottie/cat_arkvb0.json',
];

export function loadingAnimationUrl() {
  return sample(loadingUrls);
}

const emailAnimationUrls = ['https://res.cloudinary.com/ft-bounty/raw/upload/v1694112125/app-materials/lottie/email_sent_xay8en.json'];

export async function emailAnimation() {
  return getAnimation(sample(emailAnimationUrls));
}
