import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import InfiniteScroll from 'react-infinite-scroller';
import { Asset } from '../../generated/app_server_sdk';
import { useEvent } from '../../hooks/useEvent';
import appServer from '../../utils/appServer';
import { cldTransform, md5checksum } from '../../utils/imageUtils';
import { useCloudinaryMediaLibraryWidget } from '../cloudinary-media-library-widget/CloudinaryContext';
import Iconify from '../iconify';
import { CloudinaryAsset } from '../cloudinary-media-library-widget/types';

interface AssetsDialogProps {
  onClose: () => void;
  onSelectAsset: (Asset: Asset) => void;
}

const PAGE_SIZE = 30;

const DEFAULT_ITEM_IMAGE_URL = 'https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png';

export function AssetPicker({ onClose, onSelectAsset }: AssetsDialogProps) {
  const [uploadUrl, setUploadUrl] = useState('');
  const [availableAssets, setAvailableAssets] = useState<Asset[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isCreateAssetRequestInFlight, setIsCreateAssetRequestInFlight] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [searchResults, setSearchResults] = useState<Asset[] | undefined>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchRequestInFlight, setIsSearchRequestInFlight] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const cloudinaryMediaLibrary = useCloudinaryMediaLibraryWidget();

  const firstLoadInFlight = availableAssets.length === 0 && hasMore;

  async function loadMoreAssets() {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const cursor = availableAssets.length ? String(availableAssets[availableAssets.length - 1].serial) : undefined;
    const resp = await appServer.assetsApi.listAssets(String(PAGE_SIZE), cursor);
    setAvailableAssets(availableAssets.concat(resp.items));
    setHasMore(resp.items.length >= PAGE_SIZE);
    setIsLoading(false);
    setIsCreateAssetRequestInFlight(false);
  }

  const onImageSelected = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];
    setIsCreateAssetRequestInFlight(true);
    try {
      const checksum = await md5checksum(file);
      let existingAsset = await appServer.assetsApi.findOneAssetBy({ bytes: file.size, checksum }).catch(() => null);
      if (existingAsset) {
        onSelectAsset(existingAsset);
      } else {
        const segmentBackground = true;
        const analyzeContent = true;
        const newAsset = await appServer.assetsApi.createAsset(file, 'user_product', segmentBackground, analyzeContent);
        onSelectAsset(newAsset);
      }

      onClose();
    } catch (e) {
      enqueueSnackbar('Something went wrong, please try again later', {
        key: 'CREATE_STICKER_FAILED',
        preventDuplicate: true,
        autoHideDuration: 5000,
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
    } finally {
      setIsCreateAssetRequestInFlight(false);
    }
  }, [onClose, onSelectAsset, enqueueSnackbar]);

  const searchAssets = useEvent(async (term: string) => {
    if (term === '') {
      setSearchResults(undefined);
      return;
    }

    try {
      setIsSearchRequestInFlight(true);
      const searchResult = await appServer.assetsApi.searchAssets(term);
      setSearchResults(searchResult.items);
    } catch (e) {
      enqueueSnackbar('Search failed, please try again later', {
        key: 'CREATE_STICKER_FAILED',
        preventDuplicate: true,
        autoHideDuration: 5000,
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
      setSearchResults([]);
    } finally {
      setIsSearchRequestInFlight(false);
    }
  });

  const debouncedSearchScenesRef = useRef(debounce(searchAssets, 1000));

  useEffect(() => {
    debouncedSearchScenesRef.current(searchTerm);
  }, [searchTerm]);

  function forceSearch() {
    const debouncedSearchFn = debouncedSearchScenesRef.current;
    debouncedSearchFn.cancel();
    debouncedSearchFn(searchTerm);
    debouncedSearchFn.flush();
  }

  const fetchFromUrl = useEvent(async (url: string) => {
    try {
      setIsCreateAssetRequestInFlight(true);
      const response = await fetch(url, { mode: 'cors' });
      const blob = await response.blob();
      const file = new File([blob], url);
      return file;
    } catch (e) {
      enqueueSnackbar('Failed to fetch image from URL', {
        key: 'FETCH_IMAGE_FAILED',
        preventDuplicate: true,
        autoHideDuration: 5000,
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
    } finally {
      setIsCreateAssetRequestInFlight(false);
    }
  });
  
  const onCloudinaryInsert = useCallback(async (assets: CloudinaryAsset[]) => {
    const asset = assets[0];
    const urlToFetch = asset.secure_url;
    const file = await fetchFromUrl(urlToFetch);
    if (!file) {
      return false;
    }

    await onImageSelected([file]);

    return true;
  }, [onImageSelected, fetchFromUrl]);

  const openCloudinaryMediaLibrary = () => {
    cloudinaryMediaLibrary.showMediaLibrary(
      {},
      onCloudinaryInsert,
    );
  }
  
  async function uploadFromUrl(url: string): Promise<void> {
    const urlPattern = new RegExp('^(https?)://.+');
    if (!urlPattern.test(url)) {
      enqueueSnackbar('Invalid URL', {
        key: 'INVALID_URL',
        preventDuplicate: true,
        autoHideDuration: 5000,
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
      return;
    }
    const file = await fetchFromUrl(url);
    if (!file) {
      return;
    }
    onImageSelected([file]);
  }

  return (
    <>
      <Dialog open fullWidth maxWidth='md' scroll='paper' PaperProps={{ sx: { height: 'min(620px, 80vh)' } }}>
      {isCreateAssetRequestInFlight && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 99,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  }}
                >
                  <CircularProgress size={32} sx={{ opacity: 1 }} />
                </Box>
              )}
        <Stack direction='row' justifyContent='space-between' sx={{ pr: 7 }}>
          <DialogTitle>Select Asset</DialogTitle>
          <Stack direction='row' gap={2} alignItems='baseline'>
            {isSearchRequestInFlight && <CircularProgress size={20} />}
            <TextField
              type='search'
              label='Search assets'
              variant='standard'
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  forceSearch();
                }
              }}
            />
          </Stack>
        </Stack>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon='material-symbols:close' />
        </IconButton>
        <DialogContent sx={{ px: 3, py: 2.5 }} dividers>
          {!searchResults ? (
            <>
              <InfiniteScroll
                loader={
                  <Stack alignItems='center'>
                    <CircularProgress />
                  </Stack>
                }
                hasMore={hasMore}
                loadMore={loadMoreAssets}
              >
                <Dropzone onDrop={onImageSelected} noClick multiple={false}
                  accept={{ 'image/png': [], 'image/jpeg': [], 'image/webp': [] }}>
                  {({ getRootProps, getInputProps, open }) => (
                    <Grid container spacing={2} {...getRootProps()}>
                      <input {...getInputProps()} />

                      {!firstLoadInFlight && (
                        <>
                          <Grid item md={2} sm={3} xs={4}>
                            <Card
                              sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                aspectRatio: 1,
                                border: `1px solid transparent`,
                                '&:hover': { border: (theme) => `1px solid ${theme.palette.primary.light}` },
                              }}
                              onClick={open}
                            >
                              <Stack direction='column' gap={1} alignItems='center'>
                                <Iconify icon='material-symbols:upload' sx={{ color: (theme) => theme.palette.primary.main }} />
                                <Typography variant='body2'>Upload</Typography>
                              </Stack>
                            </Card>
                          </Grid>
                          <Grid item md={2} sm={3} xs={4}>
                            <Card
                              sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                aspectRatio: 1,
                                border: `1px solid transparent`,

                                '&:hover': { border: `1px solid #3448C5` },
                              }}
                              onClick={openCloudinaryMediaLibrary}
                            >
                              <Stack direction='column' gap={1} alignItems='center'>
                                <Iconify icon='logos:cloudinary-icon' sx={{ color: (theme) => theme.palette.primary.main }} />
                                <Typography variant='body2'>Cloudinary</Typography>
                              </Stack>
                            </Card>
                          </Grid>
                        </>
                      )}
                      {availableAssets.map((asset) => (
                        <Grid item key={asset.id} md={2} sm={3} xs={4}>
                          <AssetCard
                            asset={asset}
                            onSelect={() => {
                              onSelectAsset(asset);
                              onClose();
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Dropzone>
              </InfiniteScroll>

              
            </>
          ) : searchResults.length ? (
            <Grid container spacing={2}>
              {searchResults.map((asset) => (
                <Grid item key={asset.id} md={2} sm={3} xs={4}>
                  <AssetCard
                    asset={asset}
                    onSelect={() => {
                      onSelectAsset(asset);
                      onClose();
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            searchTerm ? 'No matching results found' : ''
          )}
        </DialogContent>
        <DialogActions>
          <Stack direction='row' spacing={2} width="100%">
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label='Asset from URL'
                variant='standard'
                fullWidth
                onChange={(e) => {
                  setUploadUrl(e.target.value);
                }}
              />
            </FormControl>
            <Button onClick={() => uploadFromUrl(uploadUrl)} sx={{ visibility: uploadUrl === '' ? 'hidden' : 'visible' }}>
              Upload
            </Button>
          </Stack>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function AssetCard(props: { asset: Asset; onSelect: () => void }) {
  const imgUrl = cldTransform(props.asset.transparent_url || DEFAULT_ITEM_IMAGE_URL, { w: 200, h: 200, c: 'pad' });

  return (
    <Card
      onClick={props.onSelect}
      sx={{
        cursor: 'pointer',
        border: '1px solid transparent',
        '&:hover': {
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
        },
      }}
    >
      <img src={imgUrl} alt='' />
    </Card>
  );
}
