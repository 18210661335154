import { useRef } from 'react';
import { publish } from 'src/components/editor/Topics';

interface ILoggingOptions {
  consoleout?: boolean;
  netout?: boolean;
  context?: string;
}
let userLoggingOptions: ILoggingOptions = { consoleout: process.env.NODE_ENV === 'development', netout: false };
function setUserLoggingOptions(options: ILoggingOptions) {
  userLoggingOptions = Object.assign({}, options);
}

class Logger {
  options: ILoggingOptions;

  constructor(options: ILoggingOptions = {}) {
    this.options = Object.assign({}, userLoggingOptions, options);
  }

  private _send(data: any[]) {
    if (!this.options.netout) {
      return;
    }
    publish('log', data);
  }

  private _log(data: any[], logFunc: (...data: any[]) => void) {
    if (this.options.consoleout) {
      let logData = data;
      if (this.options.context) {
        let funcName = callerName(3);
        funcName = funcName === undefined ? '' : `.${funcName}`;
        logData = [`[${this.options.context}${funcName}]`, ...data];
      } else {
        logData = data;
      }
      logFunc(...logData);
    }
  }

  dir(...data: any[]) {
    this._log(data, console.dir);
    this._send(data);
  }

  log(...data: any[]) {
    callerName();

    this._log(data, console.log);
    this._send(data);
  }

  debug(...data: any[]) {
    this._log(data, console.debug);
    this._send(data);
  }

  info(...data: any[]) {
    this._log(data, console.info);
    this._send(data);
  }

  warn(...data: any[]) {
    this._log(data, console.warn);
    this._send(data);
  }

  error(...data: any[]) {
    this._log(data, console.error);
    this._send(data);
  }

  table(...data: any[]) {
    this._log(data, console.table);
    this._send(data);
  }
}

function createLogger(options?: ILoggingOptions) {
  if (!options) {
    options = {};
  }

  if (!options.context) {
    let e = new Error();

    let caller = (e.stack || '').split('\n')[2];
    let callerName = caller.match(/^\s+at\s([^\s]+)\s\(.*/);
    options.context = callerName?.at(1);
  }

  let opts = Object.assign({}, userLoggingOptions, options);

  return new Logger(opts);
}
function useLogger(options?: ILoggingOptions) {
  return useRef(createLogger(options)).current;
}

function callerName(functionAboveMe: number = 1) {
  let e = new Error();
  let caller = (e.stack || '').split('\n')[functionAboveMe + 1];
  let callerName = caller.match(/^\s+at\s([^\s]+)\s\(.*/);
  return callerName?.at(1);
}

export { Logger, setUserLoggingOptions, useLogger, createLogger };
