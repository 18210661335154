/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreatePhotoSessionPayload
 */
export interface CreatePhotoSessionPayload {
  /**
   *
   * @type {object}
   * @memberof CreatePhotoSessionPayload
   */
  placement_canvas_content: object;
  /**
   *
   * @type {Array<string>}
   * @memberof CreatePhotoSessionPayload
   */
  product_ids: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CreatePhotoSessionPayload
   */
  asset_ids: Array<string>;
}

/**
 * Check if a given object implements the CreatePhotoSessionPayload interface.
 */
export function instanceOfCreatePhotoSessionPayload(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'placement_canvas_content' in value;
  isInstance = isInstance && 'product_ids' in value;
  isInstance = isInstance && 'asset_ids' in value;

  return isInstance;
}

export function CreatePhotoSessionPayloadFromJSON(json: any): CreatePhotoSessionPayload {
  return CreatePhotoSessionPayloadFromJSONTyped(json, false);
}

export function CreatePhotoSessionPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePhotoSessionPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    placement_canvas_content: json['placement_canvas_content'],
    product_ids: json['product_ids'],
    asset_ids: json['asset_ids'],
  };
}

export function CreatePhotoSessionPayloadToJSON(value?: CreatePhotoSessionPayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    placement_canvas_content: value.placement_canvas_content,
    product_ids: value.product_ids,
    asset_ids: value.asset_ids,
  };
}
