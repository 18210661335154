/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateSceneRequestPayload
 */
export interface CreateSceneRequestPayload {
  /**
   *
   * @type {string}
   * @memberof CreateSceneRequestPayload
   */
  product_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateSceneRequestPayload
   */
  scene_description: string;
  /**
   *
   * @type {string}
   * @memberof CreateSceneRequestPayload
   */
  mode: CreateSceneRequestPayloadModeEnum;
}

/**
 * @export
 */
export const CreateSceneRequestPayloadModeEnum = {
  Manual: 'Manual',
  AutoGenerated: 'AutoGenerated',
} as const;
export type CreateSceneRequestPayloadModeEnum = (typeof CreateSceneRequestPayloadModeEnum)[keyof typeof CreateSceneRequestPayloadModeEnum];

/**
 * Check if a given object implements the CreateSceneRequestPayload interface.
 */
export function instanceOfCreateSceneRequestPayload(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'scene_description' in value;
  isInstance = isInstance && 'mode' in value;

  return isInstance;
}

export function CreateSceneRequestPayloadFromJSON(json: any): CreateSceneRequestPayload {
  return CreateSceneRequestPayloadFromJSONTyped(json, false);
}

export function CreateSceneRequestPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSceneRequestPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    product_id: json['product_id'],
    scene_description: json['scene_description'],
    mode: json['mode'],
  };
}

export function CreateSceneRequestPayloadToJSON(value?: CreateSceneRequestPayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product_id: value.product_id,
    scene_description: value.scene_description,
    mode: value.mode,
  };
}
