/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ImageDescriptionResponse
 */
export interface ImageDescriptionResponse {
  /**
   *
   * @type {string}
   * @memberof ImageDescriptionResponse
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof ImageDescriptionResponse
   */
  style: string;
  /**
   *
   * @type {string}
   * @memberof ImageDescriptionResponse
   */
  subject: string;
  /**
   *
   * @type {boolean}
   * @memberof ImageDescriptionResponse
   */
  nsfw: boolean;
}

/**
 * Check if a given object implements the ImageDescriptionResponse interface.
 */
export function instanceOfImageDescriptionResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'description' in value;
  isInstance = isInstance && 'style' in value;
  isInstance = isInstance && 'subject' in value;
  isInstance = isInstance && 'nsfw' in value;

  return isInstance;
}

export function ImageDescriptionResponseFromJSON(json: any): ImageDescriptionResponse {
  return ImageDescriptionResponseFromJSONTyped(json, false);
}

export function ImageDescriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageDescriptionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    description: json['description'],
    style: json['style'],
    subject: json['subject'],
    nsfw: json['NSFW'],
  };
}

export function ImageDescriptionResponseToJSON(value?: ImageDescriptionResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    style: value.style,
    subject: value.subject,
    NSFW: value.nsfw,
  };
}
