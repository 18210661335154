/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Photo } from './Photo';
import { PhotoFromJSON, PhotoFromJSONTyped, PhotoToJSON } from './Photo';

/**
 *
 * @export
 * @interface PhotoSession
 */
export interface PhotoSession {
  /**
   *
   * @type {string}
   * @memberof PhotoSession
   */
  id: string;
  /**
   *
   * @type {object}
   * @memberof PhotoSession
   */
  placement_canvas_content?: object;
  /**
   *
   * @type {Array<Photo>}
   * @memberof PhotoSession
   */
  photos: Array<Photo>;
  /**
   *
   * @type {Array<string>}
   * @memberof PhotoSession
   */
  product_ids: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PhotoSession
   */
  asset_ids?: Array<string>;
}

/**
 * Check if a given object implements the PhotoSession interface.
 */
export function instanceOfPhotoSession(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'photos' in value;
  isInstance = isInstance && 'product_ids' in value;

  return isInstance;
}

export function PhotoSessionFromJSON(json: any): PhotoSession {
  return PhotoSessionFromJSONTyped(json, false);
}

export function PhotoSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoSession {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    placement_canvas_content: !exists(json, 'placement_canvas_content') ? undefined : json['placement_canvas_content'],
    photos: (json['photos'] as Array<any>).map(PhotoFromJSON),
    product_ids: json['product_ids'],
    asset_ids: !exists(json, 'asset_ids') ? undefined : json['asset_ids'],
  };
}

export function PhotoSessionToJSON(value?: PhotoSession | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    placement_canvas_content: value.placement_canvas_content,
    photos: (value.photos as Array<any>).map(PhotoToJSON),
    product_ids: value.product_ids,
    asset_ids: value.asset_ids,
  };
}
