/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LabsTextToSubjectRequest
 */
export interface LabsTextToSubjectRequest {
  /**
   *
   * @type {string}
   * @memberof LabsTextToSubjectRequest
   */
  subject: string;
}

/**
 * Check if a given object implements the LabsTextToSubjectRequest interface.
 */
export function instanceOfLabsTextToSubjectRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'subject' in value;

  return isInstance;
}

export function LabsTextToSubjectRequestFromJSON(json: any): LabsTextToSubjectRequest {
  return LabsTextToSubjectRequestFromJSONTyped(json, false);
}

export function LabsTextToSubjectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabsTextToSubjectRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subject: json['subject'],
  };
}

export function LabsTextToSubjectRequestToJSON(value?: LabsTextToSubjectRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subject: value.subject,
  };
}
