/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SocialPost
 */
export interface SocialPost {
  /**
   *
   * @type {string}
   * @memberof SocialPost
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SocialPost
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof SocialPost
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof SocialPost
   */
  redirect_url: string;
  /**
   *
   * @type {string}
   * @memberof SocialPost
   */
  photo_id: string;
  /**
   *
   * @type {string}
   * @memberof SocialPost
   */
  photo_url: string;
  /**
   *
   * @type {string}
   * @memberof SocialPost
   */
  platform: string;
}

/**
 * Check if a given object implements the SocialPost interface.
 */
export function instanceOfSocialPost(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'title' in value;
  isInstance = isInstance && 'description' in value;
  isInstance = isInstance && 'redirect_url' in value;
  isInstance = isInstance && 'photo_id' in value;
  isInstance = isInstance && 'photo_url' in value;
  isInstance = isInstance && 'platform' in value;

  return isInstance;
}

export function SocialPostFromJSON(json: any): SocialPost {
  return SocialPostFromJSONTyped(json, false);
}

export function SocialPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialPost {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    title: json['title'],
    description: json['description'],
    redirect_url: json['redirect_url'],
    photo_id: json['photo_id'],
    photo_url: json['photo_url'],
    platform: json['platform'],
  };
}

export function SocialPostToJSON(value?: SocialPost | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    description: value.description,
    redirect_url: value.redirect_url,
    photo_id: value.photo_id,
    photo_url: value.photo_url,
    platform: value.platform,
  };
}
