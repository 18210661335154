/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CloudinaryUploadResponse
 */
export interface CloudinaryUploadResponse {
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  public_id?: string;
  /**
   *
   * @type {number}
   * @memberof CloudinaryUploadResponse
   */
  version?: number;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  signature?: string;
  /**
   *
   * @type {number}
   * @memberof CloudinaryUploadResponse
   */
  width?: number;
  /**
   *
   * @type {number}
   * @memberof CloudinaryUploadResponse
   */
  height?: number;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  format?: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  resource_type?: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  created_at?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CloudinaryUploadResponse
   */
  tags?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CloudinaryUploadResponse
   */
  bytes?: number;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  etag?: string;
  /**
   *
   * @type {boolean}
   * @memberof CloudinaryUploadResponse
   */
  placeholder?: boolean;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  secure_url?: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  access_mode?: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryUploadResponse
   */
  original_filename?: string;
}

/**
 * Check if a given object implements the CloudinaryUploadResponse interface.
 */
export function instanceOfCloudinaryUploadResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CloudinaryUploadResponseFromJSON(json: any): CloudinaryUploadResponse {
  return CloudinaryUploadResponseFromJSONTyped(json, false);
}

export function CloudinaryUploadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudinaryUploadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    public_id: !exists(json, 'public_id') ? undefined : json['public_id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    signature: !exists(json, 'signature') ? undefined : json['signature'],
    width: !exists(json, 'width') ? undefined : json['width'],
    height: !exists(json, 'height') ? undefined : json['height'],
    format: !exists(json, 'format') ? undefined : json['format'],
    resource_type: !exists(json, 'resource_type') ? undefined : json['resource_type'],
    created_at: !exists(json, 'created_at') ? undefined : json['created_at'],
    tags: !exists(json, 'tags') ? undefined : json['tags'],
    bytes: !exists(json, 'bytes') ? undefined : json['bytes'],
    type: !exists(json, 'type') ? undefined : json['type'],
    etag: !exists(json, 'etag') ? undefined : json['etag'],
    placeholder: !exists(json, 'placeholder') ? undefined : json['placeholder'],
    url: !exists(json, 'url') ? undefined : json['url'],
    secure_url: !exists(json, 'secure_url') ? undefined : json['secure_url'],
    access_mode: !exists(json, 'access_mode') ? undefined : json['access_mode'],
    original_filename: !exists(json, 'original_filename') ? undefined : json['original_filename'],
  };
}

export function CloudinaryUploadResponseToJSON(value?: CloudinaryUploadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    public_id: value.public_id,
    version: value.version,
    signature: value.signature,
    width: value.width,
    height: value.height,
    format: value.format,
    resource_type: value.resource_type,
    created_at: value.created_at,
    tags: value.tags,
    bytes: value.bytes,
    type: value.type,
    etag: value.etag,
    placeholder: value.placeholder,
    url: value.url,
    secure_url: value.secure_url,
    access_mode: value.access_mode,
    original_filename: value.original_filename,
  };
}
