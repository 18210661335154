/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PhotoEditHistory
 */
export interface PhotoEditHistory {
  /**
   *
   * @type {Array<object>}
   * @memberof PhotoEditHistory
   */
  entries: Array<object>;
  /**
   *
   * @type {number}
   * @memberof PhotoEditHistory
   */
  cursor: number;
}

/**
 * Check if a given object implements the PhotoEditHistory interface.
 */
export function instanceOfPhotoEditHistory(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'entries' in value;
  isInstance = isInstance && 'cursor' in value;

  return isInstance;
}

export function PhotoEditHistoryFromJSON(json: any): PhotoEditHistory {
  return PhotoEditHistoryFromJSONTyped(json, false);
}

export function PhotoEditHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoEditHistory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    entries: json['entries'],
    cursor: json['cursor'],
  };
}

export function PhotoEditHistoryToJSON(value?: PhotoEditHistory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    entries: value.entries,
    cursor: value.cursor,
  };
}
