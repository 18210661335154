import md5 from 'md5';
import Pako from 'pako';
import { createLogger } from './Logger';

interface ISegmentation {
  width: number;
  height: number;
  rle: Array<Array<number>>;
}
function compress(input: Record<string, any> | string, type: string | undefined = undefined): Blob {
  if (!(input instanceof String)) {
    input = JSON.stringify(input);
  }
  let enc = new TextEncoder();
  let compressed = Pako.deflate(enc.encode(input.toString()));
  const blob = new Blob([compressed], { type: type });
  return blob as Blob;
}
function rleToBitmap(segmentation: ISegmentation): Array<Array<number>> {
  let segments = segmentation.rle[0];
  let sequences = segmentation.rle[1];
  let bitmap = Array(segmentation.height).fill(Array(segmentation.width));
  let row = 0;
  let column = 0;
  // let start = Date.now()
  sequences.forEach((sequence: number, segmentIndex: number) => {
    for (let i = 0; i < sequence; i++) {
      bitmap[row][column] = segments[segmentIndex];
      column += 1;
      if (column % segmentation.width === 0) {
        row += 1;
        column = 0;
      }
    }
  });
  return bitmap;
}

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(String(reader.result));
    reader.readAsDataURL(blob);
  });
}

function cldTransform(
  url: string | undefined,
  transformation: Record<string, any> | Array<Record<string, any>>,
  queryParams: Record<string, string> = {},
): string {
  const logger = createLogger();
  if (!url) {
    // empty gif
    return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  }
  if (!url.includes('cloudinary.com')) {
    if (!url.startsWith('data:')) {
      logger.debug(`transformation ${JSON.stringify(transformation)} requested on non cloudinary url ${url}`);
    }

    return url;
  }

  let parts = url.match(/(?<base>.*)\/v(?<version>[^/]+)\/(?<public_id>.*)/);

  if (!transformation.length) {
    transformation = [transformation];
  }
  let transformation_url = transformation
    .map((component: Record<string, any>) =>
      Object.keys(component)
        .map((key) => `${key}_${component[key]}`)
        .join(','),
    )
    .join('/');

  let cldUrl = `${parts?.groups?.base}/${transformation_url}/v${parts?.groups?.version}/${parts?.groups?.public_id}`;
  if (Object.keys(queryParams).length > 0) {
    cldUrl = `${cldUrl}?${new URLSearchParams(queryParams).toString()}`;
  }
  return cldUrl;
}

async function cldExists(url: string): Promise<boolean> {
  try {
    let response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (_) {
    return false;
  }
}

export async function md5checksum(file: File) {
  // get byte array of file
  const buffer = await file.arrayBuffer();
  return md5(new Uint8Array(buffer));
}

export function debugImage(imageData: ImageData) {
  if (!imageData) return;
  if (!imageData.width || !imageData.height) return;

  try {
    const canvas = new OffscreenCanvas(imageData.width, imageData.height);
    const ctx = canvas.getContext('2d');
    if (ctx) {
      // found this function on stack overflow;
      // not sure why the below lines glow red in TS, but they seem to work
      // doesnt matter cause this is a debug util
      // @ts-ignore
      ctx.putImageData(imageData, 0, 0);
      // @ts-ignore
      canvas.convertToBlob().then((blob) => {
        const reader = new FileReader();

        reader.onload = () => {
          const dataUri = reader.result;
          console.warn('dataUri', { blob, dataUri });

          const uniqueId = new Date().getTime();
          const style = `font-size: 300px; background: url("${dataUri}#${uniqueId}") no-repeat; background-size: contain;`;
          console.log('%c     ', style);
        };

        reader.onerror = (error) => {
          console.error('Error: ', error);
        };

        reader.readAsDataURL(blob);
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export async function blobToImageData(blob: Blob): Promise<ImageData> {
  const url = URL.createObjectURL(blob); // create an Object URL
  const img = new Image(); // create a temp. image object

  return new Promise((resolve) => {
    img.onload = function () {
      // handle async image loading
      URL.revokeObjectURL(url); // free memory held by Object URL
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      if (ctx) {
        c.width = img.width;
        c.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve(ctx.getImageData(0, 0, img.width, img.height));
      }
    };

    img.src = url;
  });
}

export function proxyAvatarWithCloudinaryIfPossible(url?: string) {
  let result = url || '';
  if (result.startsWith('https://lh3.googleusercontent.com/')) {
    result = result.replace('https://lh3.googleusercontent.com/', 'https://res.cloudinary.com/ft-bounty/image/upload/v1/googleusercontent/');
  }

  return result;
}

export const BROKEN_IMAGE_URL = 'https://res.cloudinary.com/ft-bounty/image/upload/v1694704093/app-materials/broken_image.png';
export const LOGO_URL = 'https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png';
export { blobToBase64, cldExists, cldTransform, compress, rleToBitmap };
