/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import { AssetFromJSON, AssetFromJSONTyped, AssetToJSON } from './Asset';
import type { PhotoSession } from './PhotoSession';
import { PhotoSessionFromJSON, PhotoSessionFromJSONTyped, PhotoSessionToJSON } from './PhotoSession';

/**
 *
 * @export
 * @interface GetPhotoSessionResponse
 */
export interface GetPhotoSessionResponse {
  /**
   *
   * @type {PhotoSession}
   * @memberof GetPhotoSessionResponse
   */
  photo_session: PhotoSession;
  /**
   *
   * @type {Array<Asset>}
   * @memberof GetPhotoSessionResponse
   */
  assets: Array<Asset>;
}

/**
 * Check if a given object implements the GetPhotoSessionResponse interface.
 */
export function instanceOfGetPhotoSessionResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'photo_session' in value;
  isInstance = isInstance && 'assets' in value;

  return isInstance;
}

export function GetPhotoSessionResponseFromJSON(json: any): GetPhotoSessionResponse {
  return GetPhotoSessionResponseFromJSONTyped(json, false);
}

export function GetPhotoSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPhotoSessionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    photo_session: PhotoSessionFromJSON(json['photoSession']),
    assets: (json['assets'] as Array<any>).map(AssetFromJSON),
  };
}

export function GetPhotoSessionResponseToJSON(value?: GetPhotoSessionResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    photoSession: PhotoSessionToJSON(value.photo_session),
    assets: (value.assets as Array<any>).map(AssetToJSON),
  };
}
