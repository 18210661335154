/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FindOneAssetByRequestParams
 */
export interface FindOneAssetByRequestParams {
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  checksum?: string;
  /**
   *
   * @type {number}
   * @memberof FindOneAssetByRequestParams
   */
  bytes?: number;
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByRequestParams
   */
  name?: string;
}

/**
 * Check if a given object implements the FindOneAssetByRequestParams interface.
 */
export function instanceOfFindOneAssetByRequestParams(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function FindOneAssetByRequestParamsFromJSON(json: any): FindOneAssetByRequestParams {
  return FindOneAssetByRequestParamsFromJSONTyped(json, false);
}

export function FindOneAssetByRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOneAssetByRequestParams {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    checksum: !exists(json, 'checksum') ? undefined : json['checksum'],
    bytes: !exists(json, 'bytes') ? undefined : json['bytes'],
    name: !exists(json, 'name') ? undefined : json['name'],
  };
}

export function FindOneAssetByRequestParamsToJSON(value?: FindOneAssetByRequestParams | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    checksum: value.checksum,
    bytes: value.bytes,
    name: value.name,
  };
}
