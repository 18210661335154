import { useEffect, useCallback, useRef, useLayoutEffect } from 'react';

interface UsePollingParams<T> {
  enabled: boolean;
  client: () => Promise<T>;
  interval: number;
}

export function usePolling<T>({ enabled, client, interval }: UsePollingParams<T>) {
  const timeoutHandleRef = useRef<ReturnType<typeof setTimeout>>();
  const clientRef = useRef<typeof client | null>(client);
  const mountedRef = useRef(false);

  useLayoutEffect(() => {
    if (enabled) {
      clientRef.current = client;
    } else {
      clientRef.current = null;
    }
  });

  const poll = useCallback(async () => {
    if (clientRef.current) {
      return clientRef.current().finally(() => {
        if (enabled && mountedRef.current) {
          timeoutHandleRef.current = setTimeout(poll, interval);
        }
      });
    }
  }, [enabled, interval]);

  const stopPolling = useCallback(() => {
    if (timeoutHandleRef.current) {
      clearTimeout(timeoutHandleRef.current);
    }
  }, []);

  useEffect(() => {
    mountedRef.current = true;
    if (enabled) {
      void poll();
    }

    return () => {
      mountedRef.current = false;
      stopPolling();
    };
  }, [enabled, poll, stopPolling]);

  const forcePoll = useCallback(async () => {
    stopPolling();
    return poll();
  }, [poll, stopPolling]);

  return { forcePoll };
}
