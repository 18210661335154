import { ElementType, ReactNode, Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { safeLazy as lazy } from 'react-safe-lazy';
import AuthGuard from 'src/auth/AuthGuard';
import LoadingScreen from 'src/components/loading-screen';
import { OnboardingGuard } from '../components/onboarding/OnboardingGuard';
import { AuthenticatedAppContextProviders } from '../layouts/AuthenticatedAppContextProviders';
import CADLayout from '../layouts/editor';

const UserAccountPage = Loadable(lazy(() => import('src/pages/account/UserAccountPage')));
const Editor = Loadable(lazy(() => import('src/pages/editor/Editor')));
const AssetEditorPageGpt = Loadable(lazy(() => import('src/pages/asset/AssetEditorPageGpt')));
const AssetScenePicker = Loadable(lazy(() => import('src/pages/asset/AssetScenePicker')));
const Assets = Loadable(lazy(() => import('src/pages/asset/AssetsList')));
const Creations = Loadable(lazy(() => import('src/pages/bounty/Creations')));
const VirtualPhotoshoot = Loadable(lazy(() => import('src/pages/virtual-photoshoot/VirtualPhotoshoot')));
const Homepage = Loadable(lazy(() => import('src/pages/home/Homepage')));
const Page404 = Loadable(lazy(() => import('src/pages/Page404')));
const PhotoEditorPage = Loadable(lazy(() => import('src/pages/photo-editor/PhotoEditorPage')));
const CreateSceneWithPicturesPage = Loadable(lazy(() => import('src/pages/scenes/CreateSceneWithPicturesPage')));
const HomeStaging = Loadable(lazy(() => import('src/pages/labs/HomeStagingPage')));
const CinemagraphPage = Loadable(lazy(() => import('src/pages/labs/CinemagraphPage')));
const TextToImagePage = Loadable(lazy(() => import('src/pages/labs/TextToImagePage')));
const TextToSubjectPage = Loadable(lazy(() => import('src/pages/labs/TextToSubjectPage')));
const TextToGraphicDesignPage = Loadable(lazy(() => import('src/pages/labs/TextToGraphicDesignPage')));
const PackshotPage = Loadable(lazy(() => import('src/pages/labs/PackshotPage')));
const AlingProductsPlatformsPage = Loadable(lazy(() => import('src/pages/labs/AlingProductsPlatformsPage')));
const BlendTwoImagesPage = Loadable(lazy(() => import('src/pages/labs/BlendTwoImagesPage')));
const RestyleImagePage = Loadable(lazy(() => import('src/pages/labs/RestyleImagePage')));
const ConversationalPage = Loadable(lazy(() => import('src/pages/labs/ConversationalPage')));
const StudioShotPage = Loadable(lazy(() => import('src/pages/labs/StudioShotPage')));
const StandaloneEditorPage = Loadable(lazy(() => import('src/pages/standalone-editor/StandaloneEditorPage')));
const InpaintPage = Loadable(lazy(() => import('src/pages/labs/InpaintPage')));
const PedestalsLabPage = Loadable(lazy(() => import('src/pages/labs/PedestalsLab')));
const HolidayStudioShotPage = Loadable(lazy(() => import('src/pages/labs/HolidayStudioShotPage')));
const HolidayStylingPage = Loadable(lazy(() => import('src/pages/labs/HolidayStylingPage')));
const HolidayTextToGraphicDesignPage = Loadable(lazy(() => import('src/pages/labs/HolidayTextToGraphicDesignPage')));
const OnboardingFormPage = Loadable(lazy(() => import('src/pages/onboarding/OnboardingFormPage')));

// ----------------------------------------------------------------------

const ApplicationWrapper = ({ children }: { children: ReactNode }) => (
  <AuthGuard>
    <OnboardingGuard>
      <AuthenticatedAppContextProviders>{children}</AuthenticatedAppContextProviders>
    </OnboardingGuard>
  </AuthGuard>
);

export default function Router() {
  let routes = [
    {
      path: '/onboarding',
      element: (
        <AuthGuard>
          <OnboardingFormPage />
        </AuthGuard>
      ),
    },
    {
      path: '/photo-editor',
      element: (
        <ApplicationWrapper>
          <Outlet />
        </ApplicationWrapper>
      ),
      children: [{ path: '', element: <PhotoEditorPage /> }],
    },
    {
      path: '/editor',
      element: (
        <ApplicationWrapper>
          <StandaloneEditorPage />
        </ApplicationWrapper>
      ),
    },
    {
      path: '/',
      element: (
        <ApplicationWrapper>
          <CADLayout />
        </ApplicationWrapper>
      ),
      children: [
        { path: '', element: <Homepage /> },
        { path: 'edit', element: <Editor /> },
        { path: '404', element: <Page404 /> },
        { path: 'home-staging', element: <Navigate to='/labs/home-staging' replace /> },
        { path: 'cinemagraph', element: <Navigate to='/labs/cinemagraph' replace /> },
        {
          path: '/labs',
          children: [
            { path: 'home-staging', element: <HomeStaging /> },
            { path: 'cinemagraph', element: <CinemagraphPage /> },
            { path: 'text-to-image', element: <TextToImagePage /> },
            { path: 'text-to-subject', element: <TextToSubjectPage /> },
            { path: 'text-to-graphic-design', element: <TextToGraphicDesignPage /> },
            { path: 'pack-shot', element: <PackshotPage /> },
            { path: 'align-products-on-platforms', element: <AlingProductsPlatformsPage /> },
            { path: 'blend-two-images', element: <BlendTwoImagesPage /> },
            { path: 'restyle-image', element: <RestyleImagePage /> },
            { path: 'conversational-editing', element: <ConversationalPage /> },
            { path: 'studio-shot', element: <StudioShotPage /> },
            { path: 'inpaint', element: <InpaintPage /> },
            { path: 'pedestals', element: <PedestalsLabPage /> },
            { path: 'holiday-studio-shot', element: <HolidayStudioShotPage /> },
            { path: 'holiday-styling', element: <HolidayStylingPage /> },
            { path: 'holiday-text-to-graphic-design', element: <HolidayTextToGraphicDesignPage /> },
            { path: '*', element: <Navigate to='/' replace /> },
          ],
        },
        {
          path: '/creations',
          children: [
            { element: <Navigate to='/creations/list' replace />, index: true },
            { path: 'create', element: <Editor /> },
            { path: 'list', element: <Creations /> },
          ],
        },
        {
          path: '/assets',

          children: [
            { element: <Navigate to='/assets/list' replace />, index: true },
            { path: 'list', element: <Assets /> },
            { path: 'edit/:assetId', element: <AssetEditorPageGpt /> },
            { path: 'edit/:assetId/scenes', element: <AssetScenePicker /> },
          ],
        },
        {
          path: '/account',

          children: [
            { element: <Navigate to='/account/settings/general' replace />, index: true },
            { path: 'settings', element: <Navigate to='/account/settings/general' replace /> },
            { path: 'settings/:tab', element: <UserAccountPage /> },
          ],
        },
        {
          path: '/scenes',
          children: [
            { element: <Navigate to='/scenes/create' replace />, index: true },
            { path: 'create', element: <CreateSceneWithPicturesPage /> },
          ],
        },

        {
          path: '/virtual-photoshoot',
          element: <VirtualPhotoshoot />,
        },

        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
  ];

  return useRoutes(routes);
}
export function Loadable(Component: ElementType) {
  return (props: any) => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}
