// routes
// utils

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  if (base64Url === undefined) {
    return {
      exp: -1,
    };
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------
let logoutInterval: any;
const autoLogout = () => {
  clearInterval(logoutInterval);

  logoutInterval = setInterval(() => {
    const accessToken = localStorage.getItem('accessToken') || '';
    if (!accessToken) {
      logout();
    }
    const { exp } = jwtDecode(accessToken);
    const currentTime = Date.now();
    const timeLeft = exp * 1000 - currentTime;
    if (timeLeft <= 0) {
      logout();
    }
  }, 10000);
};

const logout = () => {
  localStorage.removeItem('accessToken');
  window.location.href = '/';
};
// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    autoLogout();
  } else {
    logout();
  }
};
