import { setSession } from 'src/auth/utils';
import { publish } from 'src/components/editor/Topics';
import {
  AssetsApi,
  Configuration,
  ErrorContext,
  ImageProcessingApi,
  LabsApi,
  Middleware,
  PhotosApi,
  ResponseContext,
  ScenesApi,
  SocialPostsApi,
  UtilsApi,
} from 'src/generated/app_server_sdk';
import { createLogger } from './Logger';
class ProgressNotificationMiddlware implements Middleware {
  logger = createLogger({ netout: false, consoleout: false });
  async onError?(context: ErrorContext): Promise<void> {
    if (context.url.endsWith('photoshoots/credits') || context.url.endsWith('/analytics')) {
      return;
    }
    this.logger.debug(context.url);
    publish('API_CALL_COMPLETE');
  }

  async post(context: ResponseContext): Promise<void> {
    if (context.url.endsWith('photoshoots/credits') || context.url.endsWith('/analytics')) {
      return;
    }
    this.logger.debug(context.url);
    publish('API_CALL_COMPLETE');
  }

  async pre(context: ResponseContext): Promise<void> {
    if (context.url.endsWith('photoshoots/credits') || context.url.endsWith('/analytics')) {
      return;
    }
    this.logger.debug(context.url);
    publish('API_CALL_IN_PROGRESS');
  }
}

class JWtRefreshMiddlware implements Middleware {
  async post(context: ResponseContext): Promise<void> {
    if (context.response.status === 401) {
      localStorage.removeItem('accessToken');
    }
    let token = context.response.headers.get('x-refresh-token');
    if (token) {
      setSession(token);
    }
  }
}
class Endpoints {
  hostIndex = 0;
  app_server_base_path: string;
  api_server_base_paths: string[];
  constructor() {
    this.app_server_base_path = String(process.env.REACT_APP_APP_SERVER_BASE_PATH);
    this.api_server_base_paths = process.env.REACT_APP_API_SERVER_BASE_PATHS ? String(process.env.REACT_APP_API_SERVER_BASE_PATHS).split(',') : [];
  }

  config(api_base_path: string) {
    let cfg: Record<string, any> = {
      basePath: api_base_path,
      headers: {},
      middleware: [new JWtRefreshMiddlware(), new ProgressNotificationMiddlware()],
    };
    if (localStorage.getItem('accessToken')) {
      cfg.headers.authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    } else {
      cfg.headers.authorization = `Bearer None`;
    }
    let url = new URL(window.location.href);

    if (url.searchParams.get('debug_images')) {
      cfg.headers['X-Debug-Images'] = '1';
    }

    return new Configuration(cfg);
  }

  get scenesApi() {
    if (!process.env.REACT_APP_APP_SERVER_BASE_PATH) {
      throw new Error(`api not configured ${process.env.REACT_APP_APP_SERVER_BASE_PATH}`);
    }

    return new ScenesApi(this.config(process.env.REACT_APP_APP_SERVER_BASE_PATH));
  }

  private get apiHost() {
    const hosts = this.api_server_base_paths.length > 0 ? this.api_server_base_paths : [this.app_server_base_path];

    this.hostIndex += 1;
    if (this.hostIndex >= hosts.length) {
      this.hostIndex = 0;
    }

    return hosts[this.hostIndex];
  }

  get photosApi() {
    return new PhotosApi(this.config(this.apiHost));
  }

  get imageProcessingApi() {
    if (!process.env.REACT_APP_APP_SERVER_BASE_PATH) {
      throw new Error(`api not configured ${process.env.REACT_APP_APP_SERVER_BASE_PATH}`);
    }

    return new ImageProcessingApi(this.config(process.env.REACT_APP_APP_SERVER_BASE_PATH));
  }

  get assetsApi() {
    if (!process.env.REACT_APP_APP_SERVER_BASE_PATH) {
      throw new Error(`api not configured ${process.env.REACT_APP_APP_SERVER_BASE_PATH}`);
    }

    return new AssetsApi(this.config(process.env.REACT_APP_APP_SERVER_BASE_PATH));
  }

  get socialPostsApi() {
    if (!process.env.REACT_APP_APP_SERVER_BASE_PATH) {
      throw new Error(`api not configured ${process.env.REACT_APP_APP_SERVER_BASE_PATH}`);
    }

    return new SocialPostsApi(this.config(process.env.REACT_APP_APP_SERVER_BASE_PATH));
  }

  get labsApi() {
    if (!process.env.REACT_APP_APP_SERVER_BASE_PATH) {
      throw new Error(`api not configured ${process.env.REACT_APP_APP_SERVER_BASE_PATH}`);
    }

    return new LabsApi(this.config(process.env.REACT_APP_APP_SERVER_BASE_PATH));
  }

  get utilsApi() {
    if (!process.env.REACT_APP_APP_SERVER_BASE_PATH) {
      throw new Error(`api not configured ${process.env.REACT_APP_APP_SERVER_BASE_PATH}`);
    }

    return new UtilsApi(this.config(process.env.REACT_APP_APP_SERVER_BASE_PATH));
  }
}
const endpoints = new Endpoints();
export default endpoints;
