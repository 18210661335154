// @mui
// auth
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import Iconify from 'src/components/iconify';
import { useLogger } from 'src/utils/Logger';
import { emailAnimation } from '../../utils/animations';

// components

// ----------------------------------------------------------------------

export default function MagicLinkAuth() {
  const logger = useLogger();
  let [email, setEmail] = useState('');
  let [checkInbox, setCheckInbox] = useState(false);

  const [animation, setAnimation] = useState();
  const [emailValid, setEmailValid] = useState(true);

  useEffect(() => {
    emailAnimation().then((r) => setAnimation(r));
  }, []);

  function validateEmail(email: string) {
    const valid = email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) !== null;
    setEmailValid(valid);
    return valid;
  }
  const handleMagicLinkLogin = async () => {
    if (!email || !emailValid) {
      setEmailValid(false);
      return;
    }

    // POST a request with the users email or phone number to the server
    setCheckInbox(true);
    let windowLocation = new URL(window.location.href);
    let url = `${process.env.REACT_APP_APP_SERVER_BASE_PATH}/auth/login/magic?return_to=${encodeURI(window.location.href)}`;
    if (windowLocation.searchParams.has('pc')) {
      url += `&pc=${windowLocation.searchParams.get('pc')}`;
    }

    fetch(url, {
      method: `POST`,
      body: JSON.stringify({
        destination: email,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.success) {
          logger.log(json.code);
        }
      });
  };

  return (
    <>
      <Dialog
        onClose={() => {
          setCheckInbox(false);
        }}
        open={checkInbox}
        fullWidth
        fullScreen={false}
        maxWidth='sm'
      >
        <DialogActions>
          <IconButton
            // variant='outlined'
            // color='info'
            // startIcon={<Iconify icon='material-symbols:close' />}
            onClick={() => {
              setCheckInbox(false);
            }}
          >
            <Iconify icon='material-symbols:close' />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ px: 2, pb: 4, overflow: 'scroll' }}>
          <Stack direction='column' alignItems='center'>
            {animation && <Lottie play loop animationData={animation} style={{ width: 300, height: 300 }} />}
            <Typography variant='h3' paragraph align='center'>
              We've sent you an email
            </Typography>
            <Typography align='center'>
              We are thrilled to see you! Your secured login link has been sent to{' '}
              <Typography color='primary' component='span'>
                {email}
              </Typography>
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>

      <Stack spacing={2}>
        <TextField
          fullWidth
          error={!emailValid}
          name='email'
          label='Login with your work email'
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            validateEmail(e.target.value);
          }}
        />
        <LoadingButton
          fullWidth
          color='inherit'
          size='large'
          type='submit'
          variant='contained'
          loading={false}
          onClick={handleMagicLinkLogin}
          startIcon={<Iconify icon='mdi:envelope-outline' style={{ color: '#e11374' }} />}
        >
          Continue
        </LoadingButton>
      </Stack>
    </>
  );
}
