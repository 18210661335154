import { createContext, useContext, useState, ReactNode, useEffect, useRef, useCallback } from 'react';
import { type CloudinaryMediaLibrary, type CloudinaryAsset, CloudinaryMediaLibraryOptions } from './types';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSnackbar } from '../snackbar';
import { Link } from '@mui/material';

interface CloudinaryMediaLibraryWidgetContextValue {
  showMediaLibrary: (options: CloudinaryMediaLibraryOptions, onSelectAsset: (assets: CloudinaryAsset[]) => void) => void;
  refreshMediaLibrary: (options: CloudinaryMediaLibraryOptions, onSelectAsset: (assets: CloudinaryAsset[]) => void, shouldOpen: boolean) => void;
}

export const CloudinaryMediaLibraryWidgetContext = createContext<null | CloudinaryMediaLibraryWidgetContextValue>(null);

export function useCloudinaryMediaLibraryWidget() {
    const value = useContext(CloudinaryMediaLibraryWidgetContext);

    if (!value) {
        throw new Error('CloudinaryMediaLibraryWidgetContext value not found');
    }
    
    return value;
}

export function CloudinaryMediaLibraryWidgetContextProvider({ children }: { children?: ReactNode }) {
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [mediaLibrary, setMediaLibrary] = useState<CloudinaryMediaLibrary | null>(null);
  const onWidgetInsert = useRef<(assets: CloudinaryAsset[]) => void>(() => {});

  const contextValue = {
    showMediaLibrary: (options: CloudinaryMediaLibraryOptions, onSelectAsset: (assets: CloudinaryAsset[]) => void) => {
      onWidgetInsert.current = onSelectAsset;
      if (!user?.settings?.cld_api_key || !user?.settings?.cld_cloud_name) {
        enqueueSnackbar('Please set Cloudinary credentials first.', { variant: 'error', action: <Link href={'/account/settings/cloudinary'}>Go to settings</Link> });
        return;
      }
      mediaLibrary?.show(options);
    },
    refreshMediaLibrary: (options: CloudinaryMediaLibraryOptions, onSelectAsset: (assets: CloudinaryAsset[]) => void, shouldOpen = false) => {
      onWidgetInsert.current = onSelectAsset;
      createMediaLibrary(options, shouldOpen);
    }
  };

  const createMediaLibrary = useCallback((optionsToOverride: CloudinaryMediaLibraryOptions, shouldOpen = false) => {
    if (window.cloudinary && user?.settings?.cld_api_key && user?.settings?.cld_cloud_name) {
      const mediaLibrary = window.cloudinary[shouldOpen ? 'openMediaLibrary' : 'createMediaLibrary'](
        {
          cloud_name: user.settings.cld_cloud_name,
          api_key: user.settings.cld_api_key,
          multiple: false,
          ...optionsToOverride,
        },
        {
          insertHandler: (data) => onWidgetInsert.current(data.assets),
        },
      );
      setMediaLibrary(mediaLibrary)
    }
  }, [user]);

  useEffect(() => {
    createMediaLibrary({});
  }, [createMediaLibrary]);
  
  return (
    <CloudinaryMediaLibraryWidgetContext.Provider value={contextValue}>
      {children}
    </CloudinaryMediaLibraryWidgetContext.Provider>
  )
}