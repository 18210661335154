/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OnboardingFormSubmissionBodyContentsInner } from './OnboardingFormSubmissionBodyContentsInner';
import {
  OnboardingFormSubmissionBodyContentsInnerFromJSON,
  OnboardingFormSubmissionBodyContentsInnerFromJSONTyped,
  OnboardingFormSubmissionBodyContentsInnerToJSON,
} from './OnboardingFormSubmissionBodyContentsInner';

/**
 *
 * @export
 * @interface OnboardingFormSubmissionBody
 */
export interface OnboardingFormSubmissionBody {
  /**
   *
   * @type {Array<OnboardingFormSubmissionBodyContentsInner>}
   * @memberof OnboardingFormSubmissionBody
   */
  contents: Array<OnboardingFormSubmissionBodyContentsInner>;
}

/**
 * Check if a given object implements the OnboardingFormSubmissionBody interface.
 */
export function instanceOfOnboardingFormSubmissionBody(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'contents' in value;

  return isInstance;
}

export function OnboardingFormSubmissionBodyFromJSON(json: any): OnboardingFormSubmissionBody {
  return OnboardingFormSubmissionBodyFromJSONTyped(json, false);
}

export function OnboardingFormSubmissionBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingFormSubmissionBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    contents: (json['contents'] as Array<any>).map(OnboardingFormSubmissionBodyContentsInnerFromJSON),
  };
}

export function OnboardingFormSubmissionBodyToJSON(value?: OnboardingFormSubmissionBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    contents: (value.contents as Array<any>).map(OnboardingFormSubmissionBodyContentsInnerToJSON),
  };
}
