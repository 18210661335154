/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateSocialPostRequest
 */
export interface UpdateSocialPostRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateSocialPostRequest
   */
  platform: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSocialPostRequest
   */
  photo_url?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSocialPostRequest
   */
  redirect_url: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSocialPostRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSocialPostRequest
   */
  title: string;
}

/**
 * Check if a given object implements the UpdateSocialPostRequest interface.
 */
export function instanceOfUpdateSocialPostRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'platform' in value;
  isInstance = isInstance && 'redirect_url' in value;
  isInstance = isInstance && 'description' in value;
  isInstance = isInstance && 'title' in value;

  return isInstance;
}

export function UpdateSocialPostRequestFromJSON(json: any): UpdateSocialPostRequest {
  return UpdateSocialPostRequestFromJSONTyped(json, false);
}

export function UpdateSocialPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSocialPostRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    platform: json['platform'],
    photo_url: !exists(json, 'photo_url') ? undefined : json['photo_url'],
    redirect_url: json['redirect_url'],
    description: json['description'],
    title: json['title'],
  };
}

export function UpdateSocialPostRequestToJSON(value?: UpdateSocialPostRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    platform: value.platform,
    photo_url: value.photo_url,
    redirect_url: value.redirect_url,
    description: value.description,
    title: value.title,
  };
}
