/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import { AssetFromJSON, AssetFromJSONTyped, AssetToJSON } from './Asset';
import type { PhotoEditHistory } from './PhotoEditHistory';
import { PhotoEditHistoryFromJSON, PhotoEditHistoryFromJSONTyped, PhotoEditHistoryToJSON } from './PhotoEditHistory';
import type { Product } from './Product';
import { ProductFromJSON, ProductFromJSONTyped, ProductToJSON } from './Product';
import type { Scene } from './Scene';
import { SceneFromJSON, SceneFromJSONTyped, SceneToJSON } from './Scene';

/**
 *
 * @export
 * @interface Photo
 */
export interface Photo {
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof Photo
   */
  serial: number;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  image_url: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  removed_artifacts_image_url: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  removed_product_image_url?: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  upscaled_url?: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  edited_image_url?: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  presentation_url?: string;
  /**
   *
   * @type {Scene}
   * @memberof Photo
   */
  scene: Scene;
  /**
   *
   * @type {Product}
   * @memberof Photo
   */
  product?: Product;
  /**
   *
   * @type {Asset}
   * @memberof Photo
   */
  asset?: Asset;
  /**
   *
   * @type {object}
   * @memberof Photo
   */
  placement_canvas_content?: object;
  /**
   *
   * @type {number}
   * @memberof Photo
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof Photo
   */
  height: number;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  session_id: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  asset_id: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  scene_id: string;
  /**
   *
   * @type {PhotoEditHistory}
   * @memberof Photo
   */
  edit_history: PhotoEditHistory;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  prompt: string;
}

/**
 * Check if a given object implements the Photo interface.
 */
export function instanceOfPhoto(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'serial' in value;
  isInstance = isInstance && 'image_url' in value;
  isInstance = isInstance && 'removed_artifacts_image_url' in value;
  isInstance = isInstance && 'scene' in value;
  isInstance = isInstance && 'width' in value;
  isInstance = isInstance && 'height' in value;
  isInstance = isInstance && 'session_id' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'asset_id' in value;
  isInstance = isInstance && 'scene_id' in value;
  isInstance = isInstance && 'edit_history' in value;
  isInstance = isInstance && 'prompt' in value;

  return isInstance;
}

export function PhotoFromJSON(json: any): Photo {
  return PhotoFromJSONTyped(json, false);
}

export function PhotoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Photo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    serial: json['serial'],
    image_url: json['image_url'],
    removed_artifacts_image_url: json['removed_artifacts_image_url'],
    removed_product_image_url: !exists(json, 'removed_product_image_url') ? undefined : json['removed_product_image_url'],
    upscaled_url: !exists(json, 'upscaled_url') ? undefined : json['upscaled_url'],
    edited_image_url: !exists(json, 'edited_image_url') ? undefined : json['edited_image_url'],
    presentation_url: !exists(json, 'presentation_url') ? undefined : json['presentation_url'],
    scene: SceneFromJSON(json['scene']),
    product: !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
    asset: !exists(json, 'asset') ? undefined : AssetFromJSON(json['asset']),
    placement_canvas_content: !exists(json, 'placement_canvas_content') ? undefined : json['placement_canvas_content'],
    width: json['width'],
    height: json['height'],
    session_id: json['session_id'],
    created_at: json['created_at'],
    asset_id: json['asset_id'],
    scene_id: json['scene_id'],
    edit_history: PhotoEditHistoryFromJSON(json['edit_history']),
    prompt: json['prompt'],
  };
}

export function PhotoToJSON(value?: Photo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    serial: value.serial,
    image_url: value.image_url,
    removed_artifacts_image_url: value.removed_artifacts_image_url,
    removed_product_image_url: value.removed_product_image_url,
    upscaled_url: value.upscaled_url,
    edited_image_url: value.edited_image_url,
    presentation_url: value.presentation_url,
    scene: SceneToJSON(value.scene),
    product: ProductToJSON(value.product),
    asset: AssetToJSON(value.asset),
    placement_canvas_content: value.placement_canvas_content,
    width: value.width,
    height: value.height,
    session_id: value.session_id,
    created_at: value.created_at,
    asset_id: value.asset_id,
    scene_id: value.scene_id,
    edit_history: PhotoEditHistoryToJSON(value.edit_history),
    prompt: value.prompt,
  };
}
