/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductPlacement
 */
export interface ProductPlacement {
  /**
   *
   * @type {boolean}
   * @memberof ProductPlacement
   */
  ceiling?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductPlacement
   */
  wall?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductPlacement
   */
  shelf?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductPlacement
   */
  table?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductPlacement
   */
  floor?: boolean;
}

/**
 * Check if a given object implements the ProductPlacement interface.
 */
export function instanceOfProductPlacement(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ProductPlacementFromJSON(json: any): ProductPlacement {
  return ProductPlacementFromJSONTyped(json, false);
}

export function ProductPlacementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPlacement {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ceiling: !exists(json, 'ceiling') ? undefined : json['ceiling'],
    wall: !exists(json, 'wall') ? undefined : json['wall'],
    shelf: !exists(json, 'shelf') ? undefined : json['shelf'],
    table: !exists(json, 'table') ? undefined : json['table'],
    floor: !exists(json, 'floor') ? undefined : json['floor'],
  };
}

export function ProductPlacementToJSON(value?: ProductPlacement | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ceiling: value.ceiling,
    wall: value.wall,
    shelf: value.shelf,
    table: value.table,
    floor: value.floor,
  };
}
