import { merge } from 'lodash';
import React, { createContext, useState } from 'react';
import { Asset } from 'src/generated/app_server_sdk';

class CacheModel {
  private static _instance: CacheModel;
  file: File | undefined;
  asset: Asset | undefined;

  static instance(): CacheModel {
    if (!this._instance) {
      this._instance = new CacheModel();
    }
    return this._instance;
  }

  clone(): CacheModel {
    let clone = Object.create(Object.getPrototypeOf(this));
    merge(clone, this);
    return clone;
  }
}

interface Props {
  children: React.ReactNode;
}
const AppCacheContext = createContext<[CacheModel, React.Dispatch<React.SetStateAction<CacheModel>>]>([CacheModel.instance(), () => {}]);

const AppCacheProvider: React.FC<Props> = ({ children }) => {
  const [cache, setCache] = useState<CacheModel>(CacheModel.instance());

  return <AppCacheContext.Provider value={[cache, setCache]}>{children}</AppCacheContext.Provider>;
};

export { AppCacheContext, AppCacheProvider, CacheModel };
